import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Modal,
    Button,
    Form,
    Dropdown,
    Table,
    Accordion,
    Card,
} from "react-bootstrap";
import DashboardLeftPanel from "../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../components/DashboardRightPanel/dashbaordrightpanel";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import "./dashboard.css";
import axios from "../../axios/axios";
import message from "../../messages";
import { Audio } from "react-loader-spinner";
import moment from "moment";
import '../Home/Home.css';

import {
    LoginLogo,
    Art1,
    Art2,
    Art3,
    Art4,
    Gadget,
    Facebook,
    Clock,
    InsIcon,
    TwitterIcon,
    FacebookIcon,
    InsIconn,
    Toggle,
    Wp,
    LinkedSocial,
    FacebookSocial,
    TwitterSocial,
    InsSocial,
    no_image,
    no_prf,
    GptScreenshot,
    Grad4,
    Grad5,
    Reg,
    Topic,
    Generate,
    GPTScreen,
    GeneratorGrad,
    GenerateCont,
    Grad6,
    Top1,
    Iconss2,
    Globe,
    Iconss3,
    Seo,
    Calendar,
    Publish,
    Scheduling,
    Analytical,
    Grad7,
    Grad8,
    Grad9,
    ParentalControl,
    Grad10,
    Grad11,
    Grad12,
    ManImage,
    Social,
    SocialMediaGrad,
    TwitterBg,
    OpenUpBg,
    RewritterBg,
    ArticleBG,
    YellowStar,
    Blogger,
    TestGrad,
    AccordionGrad,
    PlayButton,
} from "../../assets/images";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const DashboardHomePage = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [loader, setLoader] = useState(false);
    const [mediaData, setMediaData] = useState([]);
    const [recentPost, setRecentPost] = useState([]);
    const [weeklyPost, setWeeklyPost] = useState([]);
    const [socialEngagement, setSocialEngagement] = useState([]);

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
        setExpanded(!expanded);
    };
    // Effect to load the state from localStorage on initial render
    useEffect(() => {
        const storedCollapsed = localStorage.getItem('collapsed');
        if (storedCollapsed) {
            setCollapsed(JSON.parse(storedCollapsed));
        }
    }, []);

    // Effect to save the state to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('collapsed', JSON.stringify(collapsed));
    }, [collapsed]);



    // Effect to load the state from localStorage on initial render
    useEffect(() => {
        const storedExpanded = localStorage.getItem('expanded');
        if (storedExpanded) {
            setExpanded(JSON.parse(storedExpanded));
        }
    }, []);

    // Effect to save the state to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('expanded', JSON.stringify(collapsed));
    }, [collapsed]);
    const getDashboardReport = async () => {
        try {
            setLoader(true);
            await axios
                .get("/dashboard-reports")
                .then((res) => {
                    setLoader(false);
                    setMediaData(res.data.data);
                    setRecentPost(res.data.data.recent_post_articles);
                    setWeeklyPost(res.data.data.weekly_post_reports);
                    setSocialEngagement(res.data.data.social_engagement);
                })
                .catch((err) => {
                    setLoader(false);
                    console.log("err", err);
                    message.error(err.response.data.message);
                });
        } catch (err) {

        }
    };

    useEffect(() => {
        getDashboardReport();
    }, []);

    const labels = ["S", "M", "T", "W", "T", "F", "S"];
    const options = {
        responsive: true,
        plugins: {
            legend: {
                // position: 'top' as const,
            },
            title: {
                display: false,
                text: "Chart.js Bar Chart",
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: "Weekly Post",
                data: weeklyPost.map((ele) => ele),
                backgroundColor: "rgba(63, 62, 237, 1)",
            },
        ],
    };

    // const data1 = [
    //     {
    //       id: 1,
    //       campaign: (
    //         <div className="d-flex align-items-center campaign_text">
    //           <span>
    //             <img src={FacebookIcon} />
    //           </span>
    //           Facebook
    //         </div>
    //       ),
    //       articles: <div className="campaign_text">98</div>,
    //       views: <div className="campaign_text">22,895</div>,
    //       likes: <div className="campaign_text">11,121</div>,
    //       comments: <div className="campaign_text">7,654</div>,
    //     },
    //     {
    //       id: 2,
    //       campaign: (
    //         <div className="d-flex align-items-center campaign_text">
    //           <span>
    //             <img src={InsIconn} />
    //           </span>
    //           Instagram
    //         </div>
    //       ),
    //       articles: <div className="campaign_text">128</div>,
    //       views: <div className="campaign_text">17,653</div>,
    //       likes: <div className="campaign_text">6,765</div>,
    //       comments: <div className="campaign_text">3,899</div>,
    //     },

    //     {
    //       id: 2,
    //       campaign: (
    //         <div className="d-flex align-items-center campaign_text">
    //           <span>
    //             <img src={InsIconn} />
    //           </span>
    //           Instagram
    //         </div>
    //       ),
    //       articles: <div className="campaign_text">128</div>,
    //       views: <div className="campaign_text">17,653</div>,
    //       likes: <div className="campaign_text">6,765</div>,
    //       comments: <div className="campaign_text">3,899</div>,
    //     },
    //     {
    //       id: 2,
    //       campaign: (
    //         <div className="d-flex align-items-center campaign_text">
    //           <span>
    //             <img src={InsIconn} />
    //           </span>
    //           Instagram
    //         </div>
    //       ),
    //       articles: <div className="campaign_text">128</div>,
    //       views: <div className="campaign_text">17,653</div>,
    //       likes: <div className="campaign_text">6,765</div>,
    //       comments: <div className="campaign_text">3,899</div>,
    //     },
    //     {
    //       id: 2,
    //       campaign: (
    //         <div className="d-flex align-items-center campaign_text">
    //           <span>
    //             <img src={InsIconn} />
    //           </span>
    //           Instagram
    //         </div>
    //       ),
    //       articles: <div className="campaign_text">128</div>,
    //       views: <div className="campaign_text">17,653</div>,
    //       likes: <div className="campaign_text">6,765</div>,
    //       comments: <div className="campaign_text">3,899</div>,
    //     },
    //   ];

    const getEngagement = () => {
        let engagement = [];
        socialEngagement.map((item, i) => {
            Object.keys(item).map((ele, ind) => {
                engagement.push({
                    id: i + 1,
                    campaign: (
                        <div className="d-flex align-items-center campaign_text">
                            <span>
                                {ele == "facebook" ? (
                                    <img
                                        style={{ width: "30px", height: "30px" }}
                                        src={FacebookSocial}
                                    />
                                ) : ele == "twitter" ? (
                                    <img
                                        style={{ width: "30px", height: "30px" }}
                                        src={TwitterSocial}
                                    />
                                ) : ele == "instagram" ? (
                                    <img
                                        style={{ width: "30px", height: "30px" }}
                                        src={InsSocial}
                                    />
                                ) : ele == "wordpress" ? (
                                    <img style={{ width: "30px", height: "30px" }} src={Wp} />
                                ) : ele == "linkedin" ? (
                                    <img
                                        style={{ width: "30px", height: "30px" }}
                                        src={LinkedSocial}
                                    />
                                ) : (
                                    <></>
                                )}
                            </span>
                            {ele}
                        </div>
                    ),
                    articles: <div className="campaign_text">{item[ele].posts}</div>,
                    likes: <div className="campaign_text">{item[ele].likes}</div>,
                    comments: <div className="campaign_text">{item[ele].comments}</div>,
                });
            });
        });
        return engagement;
    };

    const columns = [
        {
            name: "Campaign",
            selector: (row) => row.campaign,
            width: "200px",
            style: {
                padding: "10px 7px",
                // height: "50px",
                // display: "block",
            },
        },
        {
            name: "Total no. of articles",
            selector: (row) => row.articles,
            width: "180px",
            style: {
                padding: "10px 7px",
                // height: "50px",
                // display: "block",
            },
        },
        {
            name: "Total likes",
            selector: (row) => row.likes,
            style: {
                padding: "10px 7px",
                // height: "50px",
                // display: "block",
            },
        },
        {
            name: "Total Comments",
            selector: (row) => row.comments,
            style: {
                padding: "10px 7px",
                // height: "50px",
                // display: "block",
            },
        },
    ];
    return (
        <section className="dash_partts">
            {loader == true && (
                <div className="loader-wrapper">
                    <Audio
                        height="80"
                        width="80"
                        radius="9"
                        color="green"
                        ariaLabel="loading"
                        wrapperStyle
                        wrapperClass
                    />
                </div>
            )}
            <div className="dash_inner_partts">
                <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
                    <DashboardLeftPanel />
                </div>

                <div className={`dash_right_panel ${expanded ? "expanded" : ""}`}>
                    <button onClick={toggleSidebar} className="toggle__button">
                        <img src={Toggle} />
                    </button>
                    <div className="dash_right_inner_panel">
                        <Row>
                            <Col lg="9" md="12" sm="12">
                                <div className=''>
                                    <div className='banner_partts'>
                                        <Container>
                                            <div className='banner_content'>
                                                <h1>Engage your organization community with <span>Artificial Intelligence</span> Generated content</h1>
                                                <p>Create SEO-optimized and plagiarism-free contentfor your blogs, ads, social , and website 10X faster.</p>
                                                <div className='get_started_button'>
                                                    <Link to="/generate-article">Get Started</Link>
                                                    <Link className="ml-5" to="/plan">Upgrade</Link>

                                                </div>
                                            </div>
                                        </Container>
                                    </div>
                                </div>
                                <div className='how_it_works_sec'>
                                    <Container>
                                        <div className='how_works'>
                                            <span>HOW IT WORKS</span>
                                            <h2>Instruct To Our AI and Generate Copy</h2>
                                            <p>Give our AI a few descriptions and we’ll automatically create blog articles, product descriptions and more for you within just few second.</p>
                                            <div className='abs_img_2'>
                                                <img src={Grad4} className='grad__4' width={600} />
                                                <img src={Grad5} className='grad__5' width={600} />
                                            </div>
                                        </div>
                                        <div className='register_boxx'>
                                            <Row>
                                                <Col lg="6" md="12">
                                                    <div className='register_boxx_left'>
                                                        <ul>
                                                            <li>
                                                                <div className='li_left'>
                                                                    <img src={Reg} width={40} />
                                                                </div>
                                                                <div className='li_right'>
                                                                    <h3>Register and subscribe</h3>
                                                                    <p>Simply register your self and subscribe and then create content for blog posts, landing page, website content etc.</p>
                                                                </div>
                                                            </li>


                                                            <li>
                                                                <div className='li_left'>
                                                                    <img src={Topic} width={40} />
                                                                </div>
                                                                <div className='li_right'>
                                                                    <h3>Describe your topic</h3>
                                                                    <p>Provide our AI content writer with few sentences on what you want to write, and it will start writing for you.</p>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='li_left'>
                                                                    <img src={Generate} width={40} />
                                                                </div>
                                                                <div className='li_right'>
                                                                    <h3>Generate quality content</h3>
                                                                    <p>Our powerful AI tools will generate content in few second, then you can export it to wherever you need.</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                                <Col lg="6" md="12">
                                                    <div className='gpt_screen'>
                                                        <img src={GPTScreen} className='img-fluid' />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Container>
                                </div>
                            </Col>




                            <Col lg="3" md="12" sm="12" className="pr-0">
                                <div className="dash_right_paertts">
                                    <DashRightPanel />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DashboardHomePage;
