import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
  Accordion,
  Card,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./registration.css";
import { LoginLogo } from "../../assets/images";
import axios from "../../axios/axios";
import message from "../../messages";
import { Audio } from "react-loader-spinner";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const IndivRegistration = () => {
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isValidPhn, setIsValidPhn] = useState(false);
  const [memberCountryCode, setMemberCountryCode] = useState("us");

  function validateName(name) {
    if (!name) {
      return "Name is required";
    }
    return "";
  }
  function validateEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return "Email is required";
    } else if (!emailPattern.test(email)) {
      return "Invalid email format";
    }
    return "";
  }
  function validatePhone(phone) {
    // const phonePattern = /^[0-9]{10}$/; // Adjust the pattern as needed
    if (!phone) {
      return "Phone number is required";
    }
    // else if (!phonePattern.test(phone)) {
    //   return "Invalid phone number format";
    // }
    return "";
  }
  function validatePassword(password) {
    if (password.length < 6) {
      return "Password must be at least 6 characters long";
    }
    return "";
  }
  function validateConfirmPassword(cpassword) {
    if (cpassword !== password) {
      return "Passwords do not match";
    }
    return "";
  }

  const handlePhone = (value, data, event, formattedValue) => {
    setPhone(formattedValue);
    setMemberCountryCode(data.country);
    const numericPhoneNumber = value ? value.replace(/\D/g, "") : "";
    numericPhoneNumber.length > 10 ? setIsValidPhn(false) : setIsValidPhn(true);
  };
  function handleNameChange(event) {
    const value = event.target.value;
    setName(value);
    const errorMessage = validateName(value);
    setErrors((prevErrors) => ({ ...prevErrors, name: errorMessage }));
  }
  function handleEmailChange(event) {
    const value = event.target.value;
    setEmail(value);
    const errorMessage = validateEmail(value);
    setErrors((prevErrors) => ({ ...prevErrors, email: errorMessage }));
  }
  function handlePasswordChange(event) {
    const value = event.target.value;
    setPassword(value);
    const errorMessage = validatePassword(value);
    setErrors((prevErrors) => ({ ...prevErrors, password: errorMessage }));
  }
  function handleConfirmPasswordChange(event) {
    const value = event.target.value;
    setCpassword(value);
    const errorMessage = validateConfirmPassword(value);
    setErrors((prevErrors) => ({ ...prevErrors, cpassword: errorMessage }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nameError = validateName(name);
    const emailError = validateEmail(email);
    const phoneError = validatePhone(phone);
    const passwordError = validatePassword(password);
    const confirmPasswordError = validateConfirmPassword(cpassword);

    if (
      !nameError &&
      !emailError &&
      !phoneError &&
      !isValidPhn &&
      !passwordError &&
      !confirmPasswordError
    ) {
      let paramsData = {
        name,
        phone,
        email,
        password,
      };
      setLoader(true);
      // Submit the form or perform other actions
      await axios
        .post("/individual-register", paramsData)
        .then((res) => {
          setLoader(false);
          console.log(res);
          if (res.data.status === true) {
            handleShow();
            message.success(res.data.message);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    } else {
      setErrors({
        name: nameError,
        email: emailError,
        phone: phoneError,
        password: passwordError,
        confirmPassword: confirmPasswordError,
      });
    }
  };

  const handleClose = () => {
    setTimeout(() => {
      window.open("/login", "_self");
    }, 1000);
    setShowModal(false);
  };

  const handleShow = (e) => {
    const nameError = validateName(name);
    const emailError = validateEmail(email);
    const phoneError = validatePhone(phone);
    const passwordError = validatePassword(password);
    const confirmPasswordError = validateConfirmPassword(cpassword);
    if (
      !nameError &&
      !emailError &&
      !phoneError &&
      !passwordError &&
      !confirmPasswordError
    ) {
      setShowModal(true);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="reg_page">
        {loader == true && (
          <div className="loader-wrapper">
            <Audio
              height="80"
              width="80"
              radius="9"
              color="green"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
            />
          </div>
        )}
        <Container>
          <Row className="align-items-center">
            <Col md={7} className="reg_left">
              <div className="reg_inner_left">
                {/* <div className="login_logo">
                  <img src={LoginLogo} width={200} />
                </div> */}

                <div className="reg_form">
                  <h2 className="form-title">Register here</h2>
                  <Form>
                    <Row>
                      <Col md={6}>                      
                        <Form.Group
                          className="type_email_box"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>
                            Member Name<span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={name}
                            placeholder="Type your Name"
                            className="type_email"
                            onChange={handleNameChange}
                          />
                          <div>
                            {errors.name && (
                              <span style={{ color: "red" }}>{errors.name}</span>
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group
                          className="type_email_box"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>
                            Member Email<span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={email}
                            placeholder="Type your Email"
                            className="type_email"
                            onChange={handleEmailChange}
                          />
                          <div>
                            {errors.email && (
                              <span style={{ color: "red" }}>{errors.email}</span>
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group
                          className="type_email_box"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>
                            Member Phone<span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <PhoneInput
                            value={phone}
                            country={memberCountryCode}
                            onChange={handlePhone}
                            inputStyle={{ width: "100%" }}
                            placeholder="Enter phone number"
                            className="type_email"
                            countryCodeEditable={false}
                            error={isValidPhn}
                          />
                          <div>
                            {isValidPhn && (
                              <span style={{ color: "red" }}>Invalid phone number</span>
                            )}
                            {errors.phone && (
                              <span style={{ color: "red" }}>{errors.phone}</span>
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group
                          className="type_email_box"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>
                            Member Password<span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="password"
                            name="password"
                            value={password}
                            placeholder="Type Password"
                            className="type_email"
                            onChange={handlePasswordChange}
                          />
                          <div>
                            {errors.password && (
                              <span style={{ color: "red" }}>{errors.password}</span>
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group
                          className="type_email_box"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>
                            Member Confirm Password
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="password"
                            name="cPassword"
                            value={cpassword}
                            placeholder="Type Confirm Password"
                            className="type_email"
                            onChange={handleConfirmPasswordChange}
                          />
                          <div>
                            {errors.cpassword && (
                              <span style={{ color: "red" }}>{errors.cpassword}</span>
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                  <div className="text-center mt-2">
                    <Button
                      variant="default"
                      type="submit"
                      className="btn_primary"
                      size="lg"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>

                    <div className="register_link">
                      Already have an account? <Link to="/login">Login</Link>
                    </div>
                  </div>
                </div>                               
              </div>
            </Col>
            <Col md={5} className="reg_right">
                <div className="reg_inner_right">
                  <h2>
                  Smart Post AI helps your organization to build an audience
                    organically
                  </h2>
                  <p>
                    We’re a values-driven company that provides affordable, intuitive
                    marketing tools for ambitious people and teams.
                  </p>

                  <div className="get_a_demo">
                    <Link to="">Get a Demo</Link>
                  </div>
                </div>
            </Col>
          </Row>
        </Container>        

      </div>



      <Modal show={showModal} centered size="md" className="reg_after_modal">
        <Modal.Header>
          <Modal.Title as="h5">Activate your Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0">
            Please check your <span>email Inbox</span> to activate{" "}
            <span>Smart Post AI Account</span>.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="default"
            onClick={handleClose}
            className="btn_secondary"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </form>
  );
};

export default IndivRegistration;
