import React from "react";
import axios from "../../axios/axios";
import { message } from "antd";
import { useParams } from "react-router-dom";
const EmailVerifyPage = () => {
  const { code } = useParams();
  const activeAccount = async (e) => {
    await axios
      .post("/verify-email", { token: code })
      .then((res) => {
        message.success(res.data.data.message);
        setTimeout(() => {
          window.open("/login", "_self");
        }, 2000);
      })
      .catch((err) => {
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };
  React.useEffect(() => {
    activeAccount();
  }, []);
  return (
    <div className="email_verified">
      <div className="email_verified_inner">
        <div className="email_verified_inner_inner">
            <h3>Email Verified <span>Successfully.</span> You can now log into your <span>Account</span></h3>
        </div>
      </div>
    </div>
  );
};

export default EmailVerifyPage;
