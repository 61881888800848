import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
//import "./styles.css";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
  Accordion,
  Card,
} from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import axios from "../../axios/axios";
import message from "../../messages";
import { Audio } from "react-loader-spinner";

function SsoLogin() {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  const location = useLocation();
  // const queryString = location.search;
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get("token");

  const ssoLOginAuth = async () => {
    const paramsData = {
      token: token,
    };
    setLoader(true);
    await axios
      .post("/sso-login-auth", paramsData)
      .then((res) => {
        // console.log(res.data, "<<-- res.data")
        setLoader(false);
        if (res.data.status === true) {
          message.success(res.data.message);
          localStorage.setItem("AuthUserData", JSON.stringify(res.data.data));
          localStorage.setItem("CreditDataBalance", JSON.stringify(res.data.data.user_info.credit));
          localStorage.setItem("AuthToken", res.data.data.token);
          localStorage.setItem(
            "org_info",
            JSON.stringify(res.data.data.org_info)
          );
          localStorage.setItem("role", res.data.data.user_info.type);
          setTimeout(() => {
            window.open("/dashboard-home", "_self");
          }, 1000);
        } else {
          setLoader(false);
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };
  useEffect(() => {
    ssoLOginAuth();
  }, []);
  return (
    <center>
      <h1>Welcome to Smart Post AI</h1>
      <h3>Please Wait</h3>
    </center>
  );
}

export default SsoLogin;
