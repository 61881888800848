import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./registration.css";
import { LoginLogo } from "../../assets/images";
import axios from "../../axios/axios";
import message from "../../messages";
import { Audio } from "react-loader-spinner";
import PhoneInput from "react-phone-input-2";
import PhoneInputUser from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";

const RegistrationPage = () => {
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [orgname, setOrgName] = useState("");
  const [orgphone, setOrgphone] = useState("");
  const [orgemail, setOrgemail] = useState("");
  const [orgcity, setOrgcity] = useState("");
  const [orgcountry, setOrgcountry] = useState("");
  const [orgstate, setOrgstate] = useState("");
  const [orgzipcode, setOrgzipcode] = useState("");
  const [orgurl, setOrgurl] = useState("");
  const [orgaddress, setOrgAddress] = useState("");
  const [orgErrors, setOrgErrors] = useState({});
  const [isValidOrgPhn, setIsValidOrgPhn] = useState(false);
  const [orgCountryCode, setOrgCountryCode] = useState("us");

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isValidPhn, setIsValidPhn] = useState(false);
  const [options, setOptions] = useState([]);
  const [memberCountryCode, setMemberCountryCode] = useState("in");

  const getCountryList = async () => {
    await axios
      .get(`/country-list`)
      .then((res) => {
        setLoader(false);
        let arr = [];
        for (let i = 0; i < res.data.data.length; i++) {
          let newObj = { value: res.data.data[i], label: res.data.data[i] };
          arr.push(newObj);
        }
        setOptions(arr);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCountryList();
  }, []);

  function validateOrgName(orgname) {
    if (!orgname) {
      return "Organization Name is required";
    }
    return "";
  }
  function validateOrgEmail(orgemail) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!orgemail) {
      return "Organization Email is required";
    } else if (!emailPattern.test(orgemail)) {
      return "Invalid email format";
    }
    return "";
  }
  function validateOrgPhone(orgphone) {
    if (!orgphone) {
      return " Organization Phone number is required";
    }
    return "";
  }
  function validateOrgCity(orgcity) {
    if (!orgcity) {
      return "Organization city is required";
    }
    return "";
  }
  function validateOrgCountry(orgcountry) {
    if (!orgcountry) {
      return "Organization country is required";
    }
    return "";
  }
  function validateOrgState(orgstate) {
    if (!orgstate) {
      return "Organization state is required";
    }
    return "";
  }
  function validateOrgZip(orgzipcode) {
    if (!orgzipcode) {
      return "Organization zip code is required";
    }
    return "";
  }
  function validateOrgAddress(orgaddress) {
    if (!orgaddress) {
      return "Organization address is required";
    }
    return "";
  }
  function validateOrgUrl(orgurl) {
    if (!orgurl) {
      return "Organization Url is required";
    }
    return "";
  }

  const handleOrgPhone = (value, data, event, formattedValue) => {
    setOrgphone(formattedValue);
    setOrgCountryCode(data.country);
    const numericOrgPhoneNumber = value ? value.replace(/\D/g, "") : "";
    numericOrgPhoneNumber.length > 10
      ? setIsValidOrgPhn(false)
      : setIsValidOrgPhn(true);
  };

  function handleOrgNameChange(event) {
    const value = event.target.value;
    setOrgName(value);
    const errorMessage = validateOrgName(value);
    setOrgErrors((prevErrors) => ({ ...prevErrors, orgname: errorMessage }));
  }
  function handleOrgEmailChange(event) {
    const value = event.target.value;
    setOrgemail(value);
    const errorMessage = validateOrgEmail(value);
    setOrgErrors((prevErrors) => ({ ...prevErrors, orgemail: errorMessage }));
  }
  function handleOrgPhoneChange(event) {
    const value = event.target.value;
    setOrgphone(value);
    const errorMessage = validateOrgPhone(value);
    setOrgErrors((prevErrors) => ({ ...prevErrors, orgphone: errorMessage }));
  }
  function handleOrgCityChange(event) {
    const value = event.target.value;
    setOrgcity(value);
    const errorMessage = validateOrgCity(value);
    setOrgErrors((prevErrors) => ({ ...prevErrors, orgcity: errorMessage }));
  }
  function handleOrgStateChange(event) {
    const value = event.target.value;
    setOrgstate(value);
    const errorMessage = validateOrgState(value);
    setOrgErrors((prevErrors) => ({ ...prevErrors, orgstate: errorMessage }));
  }
  function handleOrgZipChange(event) {
    const value = event.target.value;
    setOrgzipcode(value);
    const errorMessage = validateOrgZip(value);
    setOrgErrors((prevErrors) => ({ ...prevErrors, orgzipcode: errorMessage }));
  }
  // function handleOrgCountryChange(event) {
  //   const value = event.target.value;
  //   setOrgcountry(value);
  //   const errorMessage = validateOrgCountry(value);
  //   setOrgErrors((prevErrors) => ({ ...prevErrors, orgcountry: errorMessage }));
  // }

  function handleOrgUrlChange(event) {
    const value = event.target.value;
    setOrgurl(value);
    // const errorMessage = validateOrgUrl(value);
    // setOrgErrors((prevErrors) => ({ ...prevErrors, orgurl: errorMessage }));
  }

  function handleOrgAddressChange(event) {
    const value = event.target.value;
    setOrgAddress(value);
    const errorMessage = validateOrgAddress(value);
    setOrgErrors((prevErrors) => ({ ...prevErrors, orgaddress: errorMessage }));
  }

  function validateName(name) {
    if (!name) {
      return "Name is required";
    }
    return "";
  }
  function validateEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return "Email is required";
    } else if (!emailPattern.test(email)) {
      return "Invalid email format";
    }
    return "";
  }
  function validatePhone(phone) {
    if (!phone) {
      return "Phone number is required";
    }
    return "";
  }
  function validatePassword(password) {
    if (password.length < 6) {
      return "Password must be at least 6 characters long";
    }
    return "";
  }
  function validateConfirmPassword(cpassword) {
    if (cpassword !== password) {
      return "Passwords do not match";
    }
    return "";
  }

  const handlePhone = (value, data, event, formattedValue) => {
    setPhone(formattedValue);
    setMemberCountryCode(data.country);
    const numericPhoneNumber = value ? value.replace(/\D/g, "") : "";
    numericPhoneNumber.length > 10 ? setIsValidPhn(false) : setIsValidPhn(true);
  };

  function handleNameChange(event) {
    const value = event.target.value;
    setName(value);
    const errorMessage = validateName(value);
    setErrors((prevErrors) => ({ ...prevErrors, name: errorMessage }));
  }
  function handleEmailChange(event) {
    const value = event.target.value;
    setEmail(value);
    const errorMessage = validateEmail(value);
    setErrors((prevErrors) => ({ ...prevErrors, email: errorMessage }));
  }
  function handlePhoneChange(event) {
    const value = event.target.value;
    setPhone(value);
    const errorMessage = validatePhone(value);
    setErrors((prevErrors) => ({ ...prevErrors, phone: errorMessage }));
  }
  function handlePasswordChange(event) {
    const value = event.target.value;
    setPassword(value);
    const errorMessage = validatePassword(value);
    setErrors((prevErrors) => ({ ...prevErrors, password: errorMessage }));
  }
  function handleConfirmPasswordChange(event) {
    const value = event.target.value;
    setCpassword(value);
    const errorMessage = validateConfirmPassword(value);
    setErrors((prevErrors) => ({ ...prevErrors, cpassword: errorMessage }));
  }

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleNextStep = async (e) => {
    e.preventDefault();
    const orgnameError = validateOrgName(orgname);
    const orgemailError = validateOrgEmail(orgemail);
    const orgphoneError = validateOrgPhone(orgphone);
    const orgcityError = validateOrgCity(orgcity);
    const orgstateError = validateOrgState(orgstate);
    const orgCountryError = validateOrgCountry(orgcountry);
    const orgZipError = validateOrgZip(orgzipcode);
    const orgAddressError = validateOrgAddress(orgaddress);
    // const orgurlError = validateOrgUrl(orgurl);

    if (
      step === 1 &&
      !orgnameError &&
      !orgemailError &&
      !orgphoneError &&
      !isValidOrgPhn &&
      !orgcityError &&
      !orgstateError &&
      !orgCountryError &&
      !orgZipError &&
      !orgAddressError
      // !orgurlError
    ) {
      setStep(2);
      setMemberCountryCode("us");
    } else {
      setOrgErrors({
        orgname: orgnameError,
        orgemail: orgemailError,
        orgphone: orgphoneError,
        orgcity: orgcityError,
        orgcountry: orgCountryError,
        orgstate: orgstateError,
        orgzipcode: orgZipError,
        orgaddress: orgAddressError,
        // orgurl: orgurlError,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nameError = validateName(name);
    const emailError = validateEmail(email);
    const phoneError = validatePhone(phone);
    const passwordError = validatePassword(password);
    const confirmPasswordError = validateConfirmPassword(cpassword);

    if (
      step === 2 &&
      !nameError &&
      !emailError &&
      !phoneError &&
      !isValidPhn &&
      !passwordError &&
      !confirmPasswordError
    ) {
      let paramsData = {
        orgname,
        orgphone,
        orgemail,
        orgcity,
        orgcountry,
        orgstate,
        orgzipcode,
        orgaddress,
        orgurl,
        name,
        phone,
        email,
        password,
      };
      setLoader(true);
      // Submit the form or perform other actions
      await axios
        .post("/register", paramsData)
        .then((res) => {
          setLoader(false);
          console.log(res);
          if (res.data.status === true) {
            handleShow();
            message.success(res.data.message);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    } else {
      setErrors({
        name: nameError,
        email: emailError,
        phone: phoneError,
        password: passwordError,
        confirmPassword: confirmPasswordError,
      });
    }
  };
  // let defaultCountryCode = "in";
  const handleClose = () => {
    setTimeout(() => {
      window.open("/login", "_self");
    }, 1000);
    setShowModal(false);
  };
  const handleShow = (e) => {
    const nameError = validateName(name);
    const emailError = validateEmail(email);
    const phoneError = validatePhone(phone);
    const passwordError = validatePassword(password);
    const confirmPasswordError = validateConfirmPassword(cpassword);
    if (
      !nameError &&
      !emailError &&
      !phoneError &&
      !passwordError &&
      !confirmPasswordError
    ) {
      setShowModal(true);
    }
  };

  const renderStep1 = () => {
    return (
      <>
        <div className="registration_stepper">
          <div className="reg_first_partts">1</div>
          <div className="reg_dashed"></div>
          <div className="reg_second_partts">2</div>
        </div>
        <Row>
          <Col md={6}>
            <Form.Group className="type_email_box" controlId="formBasicEmail">
              <Form.Label>
                Organization Name<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="orgname"
                value={orgname}
                placeholder="Type your Organization name"
                className="type_email"
                onChange={handleOrgNameChange}
              />
              <div>
                {orgErrors.orgname && (
                  <span style={{ color: "red" }}>{orgErrors.orgname}</span>
                )}
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group
              className="type_email_box"
              controlId="formBasicPassword"
            >
              <Form.Label>
                Organization Email<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="orgemail"
                value={orgemail}
                placeholder="Type your Organization Email"
                className="type_email"
                onChange={handleOrgEmailChange}
              />
              <div>
                {orgErrors.orgemail && (
                  <span style={{ color: "red" }}>{orgErrors.orgemail}</span>
                )}
              </div>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="type_email_box" controlId="formBasicAddress">
              <Form.Label>Organization Address<span style={{ color: "red" }}>*</span></Form.Label>
              <Form.Control
                as="textarea"
                className="type_email"
                placeholder="Type your Organization Address"
                rows={3}
                value={orgaddress}
                onChange={(e) => setOrgAddress(e.target.value)}
              />
              <div>
                {orgErrors.orgaddress && (
                  <p style={{ color: "red" }}>{orgErrors.orgaddress}</p>
                )}
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="type_email_box" controlId="formBasicPhone">
              <Form.Label>
                Organization Phone<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <PhoneInput
                value={orgphone}
                country={orgCountryCode}
                autoFormat={true}
                onChange={handleOrgPhone}
                inputStyle={{ width: "100%" }}
                placeholder="Enter Organization phone number"
                className="type_email"
                countryCodeEditable={false}
                error={isValidOrgPhn}
              />
              <div>
                {isValidOrgPhn && (
                  <span style={{ color: "red" }}>
                    Invalid Organization phone number
                  </span>
                )}
                {orgErrors.orgphone && (
                  <span style={{ color: "red" }}>{orgErrors.orgphone}</span>
                )}
              </div>
            </Form.Group>
          </Col>
          
          <Col md={6}>
            <Form.Group
              className="type_email_box"
              controlId="formBasicPassword"
            >
              <Form.Label>
                Organization City<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="orgcity"
                value={orgcity}
                placeholder="Type your Organization city"
                className="type_email"
                onChange={handleOrgCityChange}
              />
              <div>
                {orgErrors.orgcity && (
                  <span style={{ color: "red" }}>{orgErrors.orgcity}</span>
                )}
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group
              className="type_email_box"
              controlId="formBasicPassword"
            >
              <Form.Label>
                Organization State<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="orgstate"
                value={orgstate}
                placeholder="Type your Organization state"
                className="type_email"
                onChange={handleOrgStateChange}
              />
              <div>
                {orgErrors.orgstate && (
                  <span style={{ color: "red" }}>{orgErrors.orgstate}</span>
                )}
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group
              className="type_email_box"
              controlId="formBasicPassword"
            >
              <Form.Label>
                Organization zip code<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="number"
                name="orgzipcode"
                value={orgzipcode}
                placeholder="Type your Organization zip"
                className="type_email"
                onChange={handleOrgZipChange}
              />
              <div>
                {orgErrors.orgzipcode && (
                  <span style={{ color: "red" }}>{orgErrors.orgzipcode}</span>
                )}
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group
              className="type_email_box"
              controlId="formBasicPassword"
            >
              <Form.Label>
                Organization country<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Select
                placeholder="Type your Organization country"
                //  className="type_email"
                defaultValue={orgcountry}
                onChange={(selectedOption) =>
                  setOrgcountry(selectedOption.value)
                }
                options={options}
                className="type_select"
                styles={{
                  control: (base, state) => ({
                    ...base,
                    height: "52px",
                    "min-height": "52px",
                    "border-radius": "10px",
                    "border-width": "2px",
                    borderColor: state.isFocused ? "#80bdff" : "#d9d9d9",
                  }),
                }}
              />
              <div>
                {orgErrors.orgcountry && (
                  <span style={{ color: "red" }}>{orgErrors.orgcountry}</span>
                )}
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group
              className="type_email_box"
              controlId="formBasicPassword"
            >
              <Form.Label>Organization Website (Optional)</Form.Label>
              <Form.Control
                type="text"
                name="orgurl"
                value={orgurl}
                placeholder="Type your Organization website"
                className="type_email"
                onChange={handleOrgUrlChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  };

  const renderStep2 = () => {
    return (
      <>
        <div className="registration_stepper">
          <div className="reg_first_partts reg_active">1</div>
          <div className="reg_dashed active"></div>
          <div className="reg_second_partts active">2</div>
        </div>
        <Row>
          <Col md={6}>
            <Form.Group className="type_email_box" controlId="formBasicEmail">
              <Form.Label>
                Member Name<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={name}
                placeholder="Type your Name"
                className="type_email"
                onChange={handleNameChange}
              />
              <div>
                {errors.name && (
                  <span style={{ color: "red" }}>{errors.name}</span>
                )}
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group
              className="type_email_box"
              controlId="formBasicPassword"
            >
              <Form.Label>
                Member Email<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={email}
                placeholder="Type your Email"
                className="type_email"
                onChange={handleEmailChange}
              />
              <div>
                {errors.email && (
                  <span style={{ color: "red" }}>{errors.email}</span>
                )}
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group
              className="type_email_box"
              controlId="formBasicuserPhone"
            >
              <Form.Label>
                Member Phone<span style={{ color: "red" }}>*</span>
              </Form.Label>

              <PhoneInputUser
                value={phone}
                country={memberCountryCode}
                autoFormat={true}
                onChange={handlePhone}
                inputStyle={{ width: "100%" }}
                placeholder="Enter phone number"
                className="type_email"
                countryCodeEditable={false}
                error={isValidPhn}
              />
              <div>
                {isValidPhn && (
                  <span style={{ color: "red" }}>Invalid phone number</span>
                )}
                {errors.phone && (
                  <span style={{ color: "red" }}>{errors.phone}</span>
                )}
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group
              className="type_email_box"
              controlId="formBasicPassword"
            >
              <Form.Label>
                Member Password<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={password}
                placeholder="Type Password"
                className="type_email"
                onChange={handlePasswordChange}
              />
              <div>
                {errors.password && (
                  <span style={{ color: "red" }}>{errors.password}</span>
                )}
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group
              className="type_email_box"
              controlId="formBasicPassword"
            >
              <Form.Label>
                Member Confirm Password
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="password"
                name="cPassword"
                value={cpassword}
                placeholder="Type Confirm Password"
                className="type_email"
                onChange={handleConfirmPasswordChange}
              />
              <div>
                {errors.cpassword && (
                  <span style={{ color: "red" }}>{errors.cpassword}</span>
                )}
              </div>
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div className="reg_page">
      {loader == true && (
        <div className="loader-wrapper">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
      <Container>
        <Row className="align-items-center">
          <Col md={7} className="reg_left">
            <div className="reg_inner_left">
              <div className="reg_form">
                <h2 className="form-title">Register here</h2>
                <Form>
                  {step === 1 ? renderStep1() : renderStep2()}

                  <div className="text-center">
                    {step > 1 && (
                      <Button
                        variant="secondary"
                        type="button"
                        className="btn_secondary btn-lg mr-2"
                        onClick={handlePreviousStep}
                      >
                        Back
                      </Button>
                    )}

                    {step === 1 && (
                      <Button
                        variant="default"
                        type="button"
                        className="btn_primary btn-lg"
                        onClick={handleNextStep}
                      >
                        Next
                      </Button>
                    )}

                    {step === 2 && (
                      <Button
                        variant="default"
                        type="submit"
                        className="btn_primary btn-lg"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    )}

                    {step && (
                      <div className="register_link">
                        Already have an account? <Link to="/login">Login</Link>
                      </div>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </Col>
          <Col md={5} className="reg_right">
            <div className="reg_inner_right">
              <h2>
              Smart Post AI helps your organization to build an audience
                organically
              </h2>
              <p>
                We’re a values-driven company that provides affordable,
                intuitive marketing tools for ambitious people and teams.
              </p>

              <div className="get_a_demo">
                <Link to="">Get a Demo</Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} centered size="lg" className="reg_after_modal">
        <Modal.Header>
          <Modal.Title>Activate your Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please check your <span>email Inbox</span> to activate{" "}
            <span>Smart Post AI Account</span>.
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center align-items-center">
          <Button
            variant="secondary"
            onClick={handleClose}
            className="reg_close"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RegistrationPage;
