import React, { useState, useContext, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

import "react-pro-sidebar/dist/css/styles.css";
import './dashboardleftpanel.css';

import {
  DashIcon,
  Members,
  Connects,
  Analytics,
  Calendar1,
  ArchiveArticle,
  Toggle,
  HomeIcon
} from "../../assets/images";

import { ThemeContext } from '../../ThemeContext';


const DashboardLeftPanel = (props) => {

  const toggleSidebar = () => {
    const appElement = document.getElementById('app');

    if (appElement) {
      appElement.classList.toggle('sideBarCollapsed');
    }
  };


  const { isDarkTheme } = useContext(ThemeContext);


  return (
    <div className="sidebar dash_left_panel">
      <div className='dash_left_inner_panel'>
        <Link className='gen_article' type='button' to="/generate-article"><label>Generate Article</label> <span className='gen_span'>+</span></Link>
        {/* <Link className='gen_article' type='button' to="/instagram-generate-article"><label>Instagram Article</label> <span className='gen_span'>+</span></Link>
        <Link className='gen_article' type='button' to="/twitter-generate-article"><label>Twitter Article</label> <span className='gen_span'>+</span></Link> */}
        <ProSidebar>
          <Menu iconShape="square">
            <MenuItem className="active"><NavLink to="/dashboard-home"><img src={HomeIcon} alt="Custom Icon" /> <span className='menu_span'>Home</span> </NavLink></MenuItem>

            <MenuItem ><NavLink to="/dashboard"><img src={DashIcon} alt="Custom Icon" /> <span className='menu_span'>Dashboard</span> </NavLink></MenuItem>

            {localStorage.getItem("role") == "Admin" && (<MenuItem><NavLink to="/manage-members"><img src={Members} alt="Custom Icon" /><span className='menu_span'> Manage Members</span></NavLink></MenuItem>)}

            <MenuItem ><NavLink to="/social-connect"><img src={Connects} alt="Custom Icon" /> <span className='menu_span'>Social Connects</span></NavLink></MenuItem>

            <MenuItem><NavLink to="/social-analytics"><img src={Analytics} alt="Custom Icon" /> <span className='menu_span'>Social Analytics</span></NavLink></MenuItem>

            <MenuItem><NavLink to="/content-calendar"><img src={Calendar1} alt="Custom Icon" /> <span className='menu_span'>Content Calendar</span></NavLink></MenuItem>

            <MenuItem><NavLink to="/archive-article"><img src={ArchiveArticle} alt="Custom Icon" /> <span className='menu_span'>Archive Article</span></NavLink></MenuItem>

            <SubMenu title="Accounts">
              <MenuItem><NavLink to="/profile">Profile</NavLink></MenuItem>
              <MenuItem><NavLink to="/plan">My Plans</NavLink></MenuItem>
              <MenuItem><NavLink to="/my-folder">My Folder</NavLink></MenuItem>
              <MenuItem><NavLink to="/support">Support</NavLink></MenuItem>
            </SubMenu>
          </Menu>
        </ProSidebar>
      </div>
      <button onClick={toggleSidebar} type='button' className="toggle__button">
        <FontAwesomeIcon icon={faAngleRight} />
      </button>
    </div>
  );
};

export default DashboardLeftPanel;
