import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Dropup,
  Table,
  Accordion,
  Card,
} from "react-bootstrap";
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../../components/DashboardRightPanel/dashbaordrightpanel";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import "./members.css";
import Switch from "react-switch";
import {
  LoginLogo,
  Art1,
  Art2,
  Art3,
  Art4,
  Gadget,
  Facebook,
  Clock,
  InsIcon,
  TwitterIcon,
  FacebookIcon,
  InsIconn,
  Toggle,
  SearchIcon,
  NameView,
  action,
} from "../../../assets/images";
import axios from "../../../axios/axios";
import message from "../../../messages";
import { Audio } from "react-loader-spinner";

const MemberPage = () => {
  const popperConfig = {
    strategy: "fixed"
  };
  const [checked, setChecked] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loader, setLoader] = useState(false);
  const [memberList, setMemberList] = useState([]);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    setExpanded(!expanded);
  };
  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedCollapsed = localStorage.getItem('collapsed');
    if (storedCollapsed) {
      setCollapsed(JSON.parse(storedCollapsed));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('collapsed', JSON.stringify(collapsed));
  }, [collapsed]);


  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedExpanded = localStorage.getItem('expanded');
    if (storedExpanded) {
      setExpanded(JSON.parse(storedExpanded));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('expanded', JSON.stringify(collapsed));
  }, [collapsed]);
  const EmailValidation = () => {
    setEmailError("");
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email) {
      setEmailError("Please enter your member's email");
      return false;
    } else if (reg.test(email) === false) {
      setEmailError("Email is invalid");
      return false;
    }
    return true;
  };

  const handleShow = (e) => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
    setEmail("");
  };

  const handleEmailSend = async (e) => {
    e.preventDefault();

    setLoader(true);
    // Submit the form or perform other actions
    if (EmailValidation()) {
      await axios
        .post(
          "/member-add",
          { email: email },
          {
            headers: { "x-access-token": localStorage.getItem("AuthToken") },
          }
        )
        .then((res) => {
          setLoader(false);
          handleClose();
          // console.log(res);
          getMemberListing()
          message.success("Invitation sent Successfully");
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }
  };

  const getMemberListing = async () => {
    setLoader(true);
    await axios
      .get("/member-list")
      .then((res) => {
        setLoader(false);
        setMemberList(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const deleteUser = (userId) => {
    // console.log(userId);
    setLoader(true);
    axios
      .post(`/member-delete`, { user_id: userId })
      .then((res) => {
        setLoader(false);
        message.success(res.data.message);
        getMemberListing();
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const memeberListing = () => {
    let member = [];
    memberList.map((item, i) => {
      member.push({
        email: (
          <div className="management_list_name">
            <div className="man_text">{item.email}</div>
          </div>
        ),
        contact: <div className="man_username">{item.phone}</div>,
        status: (<div className="man_username">{item.status}</div>
          // <Switch
          //   onChange={handleChange}
          //   checked={checked}
          //   className="react-switch"
          // />
        ),
        action: (
          <div className="action_partts" >
            <Dropdown drop="up">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={action} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Link to={`/manage-members/${item._id}`}>
                  Edit
                </Link>
                <Dropdown.Item onClick={() => deleteUser(item._id)}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
      });
    });
    return member;
  };

  useEffect(() => {
    getMemberListing();
  }, []);

  const columns = [
    {
      name: "No.",
      selector: (row, ind) => ind + 1,
      width: "70px",
      sortable: true,
      style: {
        padding: "10px 7px",
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "200px",
      sortable: true,
      style: {
        padding: "10px 7px",
      },
    },
    {
      name: "Contact No.",
      selector: (row) => row.contact,
      width: "150px",
      sortable: true,
      style: {
        padding: "10px 7px",
      },
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "140px",
      sortable: true,
      style: {
        padding: "10px 7px",
      },
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      width: "100px",
      style: {
        padding: "10px 7px",
      },
    },
  ];

  //   const data1 = [
  //     {
  //       id: 1,
  //       no: "01",
  //       name: (
  //         <div className="name_text d-flex align-items-center">
  //           <div className="name_view_sec">
  //             <img src={NameView} />
  //           </div>
  //           <div className="name_div">Dipchak</div>
  //         </div>
  //       ),
  //       email: <div className="mail">dip.wgt@gmail.com</div>,
  //       cno: <div className="mail">1234567890</div>,
  //       status: (
  //         <Switch
  //           onChange={handleChange}
  //           checked={checked}
  //           className="react-switch"
  //         />
  //       ),
  //       action: (
  //         <div className="action_partts">
  //           <Dropdown>
  //             <Dropdown.Toggle variant="success" id="dropdown-basic">
  //               <img src={action} />
  //             </Dropdown.Toggle>

  //             <Dropdown.Menu>
  //               <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
  //               <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
  //             </Dropdown.Menu>
  //           </Dropdown>
  //         </div>
  //       ),
  //     },
  //     {
  //       id: 2,
  //       no: "02",
  //       name: (
  //         <div className="name_text d-flex align-items-center">
  //           <div className="name_view_sec">
  //             <img src={NameView} />
  //           </div>
  //           <div className="name_div">Dipchak</div>
  //         </div>
  //       ),
  //       email: <div className="mail">dip.wgt@gmail.com</div>,
  //       cno: <div className="mail">1234567890</div>,
  //       status: (
  //         <Switch
  //           onChange={handleChange}
  //           checked={checked}
  //           className="react-switch"
  //         />
  //       ),
  //       action: (
  //         <div className="action_partts">
  //           <Dropdown>
  //             <Dropdown.Toggle variant="success" id="dropdown-basic">
  //               <img src={action} />
  //             </Dropdown.Toggle>

  //             <Dropdown.Menu>
  //               <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
  //               <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
  //             </Dropdown.Menu>
  //           </Dropdown>
  //         </div>
  //       ),
  //     },

  //     {
  //       id: 2,
  //       no: "03",
  //       name: (
  //         <div className="name_text d-flex align-items-center">
  //           <div className="name_view_sec">
  //             <img src={NameView} />
  //           </div>
  //           <div className="name_div">Dipchak</div>
  //         </div>
  //       ),
  //       email: <div className="mail">dip.wgt@gmail.com</div>,
  //       cno: <div className="mail">1234567890</div>,
  //       status: (
  //         <Switch
  //           onChange={handleChange}
  //           checked={checked}
  //           className="react-switch"
  //         />
  //       ),
  //       action: (
  //         <div className="action_partts">
  //           <Dropdown>
  //             <Dropdown.Toggle variant="success" id="dropdown-basic">
  //               <img src={action} />
  //             </Dropdown.Toggle>

  //             <Dropdown.Menu>
  //               <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
  //               <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
  //             </Dropdown.Menu>
  //           </Dropdown>
  //         </div>
  //       ),
  //     },
  //     {
  //       id: 2,
  //       no: "04",
  //       name: (
  //         <div className="name_text d-flex align-items-center">
  //           <div className="name_view_sec">
  //             <img src={NameView} />
  //           </div>
  //           <div className="name_div">Dipchak</div>
  //         </div>
  //       ),
  //       email: <div className="mail">dip.wgt@gmail.com</div>,
  //       cno: <div className="mail">1234567890</div>,
  //       status: (
  //         <Switch
  //           onChange={handleChange}
  //           checked={checked}
  //           className="react-switch"
  //         />
  //       ),
  //       action: (
  //         <div className="action_partts">
  //           <Dropdown>
  //             <Dropdown.Toggle variant="success" id="dropdown-basic">
  //               <img src={action} />
  //             </Dropdown.Toggle>

  //             <Dropdown.Menu>
  //               <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
  //               <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
  //             </Dropdown.Menu>
  //           </Dropdown>
  //         </div>
  //       ),
  //     },
  //     {
  //       id: 2,
  //       no: "05",
  //       name: (
  //         <div className="name_text d-flex align-items-center">
  //           <div className="name_view_sec">
  //             <img src={NameView} />
  //           </div>
  //           <div className="name_div">Dipchak</div>
  //         </div>
  //       ),
  //       email: <div className="mail">dip.wgt@gmail.com</div>,
  //       cno: <div className="mail">1234567890</div>,
  //       status: (
  //         <Switch
  //           onChange={handleChange}
  //           checked={checked}
  //           className="react-switch"
  //         />
  //       ),
  //       action: (
  //         <div className="action_partts">
  //           <Dropdown>
  //             <Dropdown.Toggle variant="success" id="dropdown-basic">
  //               <img src={action} />
  //             </Dropdown.Toggle>

  //             <Dropdown.Menu>
  //               <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
  //               <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
  //             </Dropdown.Menu>
  //           </Dropdown>
  //         </div>
  //       ),
  //     },
  //   ];
  return (
    <section className="dash_partts">
      {loader == true && (
        <div className="loader-wrapper">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
      <div className="dash_inner_partts">
        <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
          <DashboardLeftPanel />
        </div>

        <div className={`dash_right_panel ${expanded ? "expanded" : ""}`}>
          <button onClick={toggleSidebar} className="toggle__button">
            <img src={Toggle} />
          </button>
          <div className="dash_right_inner_panel">
            <Row>
              <Col lg="9" md="12" sm="12">
                <div className="dash_middle_panel">
                  <div className="manage_members_section">
                    <div className="add_mem_ber_heading">
                      <h3>Members</h3>
                      <div className="search_member_panel d-flex">
                        <div className="search_text_field">
                          <Form.Control
                            type="text"
                            placeholder="Search"
                            className="search_control_field"
                          />
                          <img src={SearchIcon} className="search_iconnn" />
                        </div>
                        <button
                          type="button"
                          className="search_member_but"
                          onClick={handleShow}
                        >
                          Add Member +
                        </button>
                      </div>
                    </div>

                    <div className="manage_data">
                      <DataTable
                        columns={columns}
                        data={memeberListing()}
                        pagination
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="12" sm="12" className="pr-0">
                <div className="dash_right_paertts">
                  <DashRightPanel />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <Modal show={showModal} centered >
        <Modal.Header>
          <Modal.Title as="h5">Add Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEmailSend}>
            <Form.Group
              className="mb-3 describe_form"
              controlId="formBasicEmail"
            >
              <Form.Label>Member Email</Form.Label>
              <Form.Control
                type="email"
                className="type_email"
                value={email}
                placeholder="Enter your member email"
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && <p style={{ color: "red" }}>{emailError}</p>}
            </Form.Group>
            <Button 
            variant="default" 
            className="btn_primary"
            >Send Invitation</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="default" 
            className="btn_primary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default MemberPage;
