import React, { useEffect, useState } from 'react';
//import "./styles.css";
import { Container, Row, Col, Modal, Button, Form, Dropdown, Table, Accordion, Card } from 'react-bootstrap';
// import LeftpanelAdmin from "../../../components/leftpanel/left-panel-admin";
// import HeaderPart from "../../../components/layout/header-admin/header";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactDOM from 'react-dom';
import ModalVideo from 'react-modal-video';
import { Link } from "react-router-dom";
import 'react-modal-video/scss/modal-video.scss';
import './Home.css';
import axios from "../../axios/axios";
import Switch from "react-switch";
import {
    GptScreenshot,
    Grad4,
    Grad5,
    Reg,
    Topic,
    Generate,
    GPTScreen,
    GeneratorGrad,
    GenerateCont,
    Grad6,
    Top1,
    Iconss2,
    Globe,
    Iconss3,
    Seo,
    Calendar,
    Publish,
    Scheduling,
    Analytical,
    Grad7,
    Grad8,
    Grad9,
    ParentalControl,
    Grad10,
    Grad11,
    Grad12,
    ManImage,
    Social,
    SocialMediaGrad,
    TwitterBg,
    OpenUpBg,
    RewritterBg,
    ArticleBG,
    YellowStar,
    Blogger,
    TestGrad,
    AccordionGrad,
    PlayButton,

} from "../../assets/images";

const Home = () => {
    const [plans, setPlans] = useState([]);
    const [checked, setChecked] = useState(false);
    const handleMonthly = () => {
        if (checked) {
            setChecked(false);
        }
    };
    const handleAnnually = () => {
        if (!checked) {
            setChecked(true);
        }
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        centerMode: true,
        centerPadding: '0px',
        responsive: [
            {
                breakpoint: 992, // Adjust the breakpoints as needed
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const [activeIndex, setActiveIndex] = useState(0);
    const [isOpen, setOpen] = useState(false);
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    useEffect(() => {
        getPlans();
    }, []);

    const getPlans = async () => {
        await axios
            .get("/plans")
            .then((res) => {
                setPlans(res.data.data);
                // console.log(res.data.data);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };
    return (
        <div className='content__partts'>
            <div className='banner_partts'>
                <Container>
                    <div className='banner_content'>
                        <h1>Engage your organization community with <span>Artificial Intelligence</span> Generated content</h1>
                        <p>Create SEO-optimized and plagiarism-free contentfor your blogs, ads, social , and website 10X faster.</p>
                        <div className='get_started_button'>
                            <Link to="/registration">Get Started</Link>
                        </div>
                    </div>
                </Container>
            </div>
            {/* <div className='gpt_screenshot'>
          <Container>
            <div className='gpt_image'>
                <img src={GptScreenshot}  className='img-fluid'/>
            </div>
          </Container>
        </div> */}


            <div className='how_it_works_sec'>
                <Container>
                    <div className='how_works'>
                        <span>HOW IT WORKS</span>
                        <h2>Instruct To Our AI and Generate Copy</h2>
                        <p>Give our AI a few descriptions and we’ll automatically create blog articles, product descriptions and more for you within just few second.</p>
                        <div className='abs_img_2'>
                            <img src={Grad4} className='grad__4' width={600} />
                            <img src={Grad5} className='grad__5' width={600} />
                        </div>
                    </div>
                    <div className='register_boxx'>
                        <Row>
                            <Col lg="6" md="12">
                                <div className='register_boxx_left'>
                                    <ul>
                                        <li>
                                            <div className='li_left'>
                                                <img src={Reg} width={40} />
                                            </div>
                                            <div className='li_right'>
                                                <h3>Register and subscribe</h3>
                                                <p>Simply register your self and subscribe and then create content for blog posts, landing page, website content etc.</p>
                                            </div>
                                        </li>


                                        <li>
                                            <div className='li_left'>
                                                <img src={Topic} width={40} />
                                            </div>
                                            <div className='li_right'>
                                                <h3>Describe your topic</h3>
                                                <p>Provide our AI content writer with few sentences on what you want to write, and it will start writing for you.</p>
                                            </div>
                                        </li>

                                        <li>
                                            <div className='li_left'>
                                                <img src={Generate} width={40} />
                                            </div>
                                            <div className='li_right'>
                                                <h3>Generate quality content</h3>
                                                <p>Our powerful AI tools will generate content in few second, then you can export it to wherever you need.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="6" md="12">
                                <div className='gpt_screen'>
                                    <img src={GPTScreen} className='img-fluid' />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <div className='why_us'>
                <Container>
                    <div className='how_works'>
                        <span>WHY US</span>
                        <h2>Features in our tool</h2>
                        <p>We’ve trained our AI with the knowledge of the best content writers so you can be sure it knows how to do its job well when generating articles for your blog.</p>
                        <div className='abs_5'>
                            <img src={Grad6} className='generator_grad2' width={700} />
                        </div>
                    </div>
                    <div className='why_us_boxx'>
                        <div className='why_us_box_inner'>
                            <div className='why_us_box_inner_inner'>
                                <div className='why_us_box_inner_box'>
                                    <div className='box_top'>
                                        <img src={Top1} width={40} />
                                    </div>
                                    <h3>Creation of Organization</h3>
                                    <p>Register your organization and get user-friendly AI content by using our content creator tool.</p>
                                </div>
                            </div>
                        </div>
                        <div className='why_us_box_inner'>
                            <div className='why_us_box_inner_inner'>
                                <div className='why_us_box_inner_box'>
                                    <div className='box_top'>
                                        <img src={Iconss2} width={40} />
                                    </div>
                                    <h3>Topic suggestion and research</h3>
                                    <p>The GPT-3 AI language model is nothing like you have seen before: natural, unique and creative.</p>
                                </div>
                            </div>
                        </div>
                        <div className='why_us_box_inner'>
                            <div className='why_us_box_inner_inner'>
                                <div className='why_us_box_inner_box'>
                                    <div className='box_top'>
                                        <img src={Globe} width={40} />
                                    </div>
                                    <h3>Social Connects</h3>
                                    <p>You can add up to five users to your organization under our subscription plan.</p>
                                </div>
                            </div>
                        </div>



                        <div className='why_us_box_inner'>
                            <div className='why_us_box_inner_inner'>
                                <div className='why_us_box_inner_box'>
                                    <div className='box_top'>
                                        <img src={Iconss3} width={40} />
                                    </div>
                                    <h3>AI Image Generator</h3>
                                    <p>Register your organization and get user-friendly AI content by using our content creator tool.</p>
                                </div>
                            </div>
                        </div>



                        <div className='why_us_box_inner'>
                            <div className='why_us_box_inner_inner'>
                                <div className='why_us_box_inner_box'>
                                    <div className='box_top'>
                                        <img src={Seo} width={40} />
                                    </div>
                                    <h3>SEO-Focused Text Editor</h3>
                                    <p>The GPT-3 AI language model is nothing like you have seen before: natural, unique and creative.</p>
                                </div>
                            </div>
                        </div>


                        <div className='why_us_box_inner'>
                            <div className='why_us_box_inner_inner'>
                                <div className='why_us_box_inner_box'>
                                    <div className='box_top'>
                                        <img src={Calendar} width={40} />
                                    </div>
                                    <h3>Content Calendar</h3>
                                    <p>You can add up to five users to your organization under our subscription plan.</p>
                                </div>
                            </div>
                        </div>


                        <div className='why_us_box_inner'>
                            <div className='why_us_box_inner_inner'>
                                <div className='why_us_box_inner_box'>
                                    <div className='box_top'>
                                        <img src={Publish} width={40} />
                                    </div>
                                    <h3>Social platform publishing</h3>
                                    <p>Register your organization and get user-friendly AI content by using our content creator tool.</p>
                                </div>
                            </div>
                        </div>

                        <div className='why_us_box_inner'>
                            <div className='why_us_box_inner_inner'>
                                <div className='why_us_box_inner_box'>
                                    <div className='box_top'>
                                        <img src={Scheduling} width={40} />
                                    </div>
                                    <h3>Scheduling Post</h3>
                                    <p>The GPT-3 AI language model is nothing like you have seen before: natural, unique and creative.</p>
                                </div>
                            </div>
                        </div>


                        <div className='why_us_box_inner'>
                            <div className='why_us_box_inner_inner'>
                                <div className='why_us_box_inner_box'>
                                    <div className='box_top'>
                                        <img src={Analytical} width={40} />
                                    </div>
                                    <h3>Analytics and performance </h3>
                                    <p>You can add up to five users to your organization under our subscription plan.</p>
                                </div>
                            </div>
                        </div>
                        <div className='abs_pos'>
                            <img src={Grad7} width={700} className='grad7' />
                            <img src={Grad9} width={700} className='grad9' />
                            <img src={Grad8} width={700} className='grad8' />
                        </div>
                    </div>
                </Container>
            </div>
            <div className='generate_content'>
                <Container>
                    <div className='how_works'>
                        {/* <span>HOW IT WORKS</span> */}
                        <h2>AI Generate content in seconds</h2>
                        <p>Generate copy that converts for business bios, facebook ads, product descriptions, emails, landing pages, social ads, and more.</p>
                        <div className='abs_5'>
                            <img src={GeneratorGrad} className='generator_grad' width={600} />

                        </div>
                    </div>
                    <div className='generqate_cont_boxx'>
                        <Row>
                            <Col lg="6" md="12">
                                <div className='generate_cont_left'>
                                    <img src={GenerateCont} className='img-fluid ' />
                                    {/* <button className="generate_video" onClick={()=> setOpen(true)} width={60}><img width={60} src={PlayButton} /></button> */}
                                    <React.Fragment>
                                        <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="UFDOY1wOOz0" onClose={() => setOpen(false)} />


                                    </React.Fragment>
                                </div>
                            </Col>
                            <Col lg="6" md="12">
                                <div className='generate_cont_right'>
                                    <ul>
                                        <li>Create article that are complete in less than 15 seconds.</li>
                                        <li>Save hundreds of hours with our AI article generator.</li>
                                        <li>Improve your copy with the article rewriter.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <div className='personal_control'>
                <Container>
                    <div className='control_row'>
                        <Row>
                            <Col lg="6" md="12">
                                <div className='personal_panel'>
                                    <h2>Get your personal control panel with dashboard</h2>
                                    <p>Generate copy that converts for business bios, facebook ads, product descriptions, emails, landing pages, social ads, and more.</p>
                                    <div className='subsbutton'>
                                        <Link to="#">Subscribe Now</Link>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="6" md="12">
                                <div className='cont_img'>
                                    <img src={ParentalControl} className='img-fluid' />
                                </div>
                            </Col>
                        </Row>
                        <div className='abs_poss'>
                            <img src={Grad10} width={600} className='grad_100' />
                        </div>
                    </div>
                </Container>
            </div>

            <div className='social_row_column'>
                <div className='social_mediaaccnt_connection'>
                    <Container>
                        <div className='social_row'>
                            <Row className='social_row_inner align-items-center'>
                                <Col lg="6" md="12" className='social_row_coloumn12'>
                                    <div className='social__img'>
                                        <img src={Social} className='img-fluid' />
                                    </div>
                                </Col>
                                <Col lg="6" md="12" className='social_row_coloumn2'>
                                    <div className='social_right' >
                                        <h2>Connect Your Social Media Accounts</h2>
                                        <p>You can connect all your social media accounts through the GPT GoodNews and it will help you to post your connect in your all social media accounts instantly.</p>
                                        <div className='subsbutton'>
                                            <Link to="#">Subscribe Now</Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <div className='social_grad'>
                        <img src={SocialMediaGrad} width={600} className='social_grad_partts' />
                        <img src={Grad12} width={700} className='social_grads_partts_2' />
                    </div>
                </div>





                <div className='social_mediaaccnt_connection'>
                    <Container>
                        <div className='social_row'>
                            <Row className='social_row_inner align-items-center'>
                                <Col lg="6" md="12" className='social_row_coloumn12'>
                                    <div className='social__img'>
                                        <img src={TwitterBg} className='img-fluid' />
                                    </div>
                                </Col>
                                <Col lg="6" md="12" className='social_row_coloumn2'>
                                    <div className='social_right' >
                                        <h2>You can check your social analytics & performance</h2>
                                        <p>Social media analytics are pieces of web application analysis. software that are used to monitor, assess and consequently improve social media performance. To track social media analytics, you'll look at likes, comments, shares and saves, but you might also monitor mentions and discussion of your brand or consumer insights by practising social listening.</p>
                                        {/* <div className='subsbutton'>
                                    <Link to="#">Subscribe Now</Link>
                                </div> */}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* <div className='social_grad'>
                <img src={SocialMediaGrad} width={600} className='social_grad_partts'/>
                <img src={Grad12} width={700} className='social_grads_partts_2' />
            </div> */}
                </div>
            </div>

            <div className='pricing'>

                <Container>
                    <div className='pricing_head'>
                        <span>Pricing</span>
                        <h2 className='title-lg text-center'>Simple Pricing, Big Savings!</h2>
                        <Row>
                            <Col md={7}>
                                <p className='sub-title mb-0'>Explore our pricing plans suitable for every budget. Get exclusive benefits with your perfect subscription plan – subscribe now!</p>
                            </Col>
                            <Col md={5} className='d-flex justify-content-end'>
                                <div className='d-inline-flex align-items-center plan_switch'>
                                    <span className={(checked==true) ? '':'active'} onClick={handleMonthly}>Monthly</span>
                                    <Switch
                                        checked={checked}
                                        onChange={() => {}}
                                        offColor="#9493fd"
                                        offHandleColor="#3f3eed"
                                        onColor="#9493fd"
                                        onHandleColor="#3f3eed"
                                        handleDiameter={30}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                    <span className={(checked==true) ? 'active':''} onClick={handleAnnually}>annually</span>
                                </div>
                            </Col>
                        </Row>
                        <div className='abs_5'>
                            <img src={Grad6} className='generator_grad2' width={700} />
                        </div>                        
                    </div>

                    <div className='pricing_coloumn'>
                        {plans?.map((plan, index) => (

                            <div className='pricing_innner_coloumn'>
                                <div className='pricing_inner_inner_column'>
                                    <div className='pricing_name'><span>{plan.name}</span></div>
                                    <div className='week_fee'><span>${plan.price} /</span>{plan.type}</div>
                                    <p>{plan.description}</p>
                                    <ul>
                                        {plan.features?.map((features, index_features) => (
                                            <>
                                                <li>{features.description} </li>
                                            </>
                                        ))}
                                    </ul>
                                    <div className='get_start_but'>
                                        <Link to="/registration">Get Started</Link>
                                    </div>
                                </div>
                            </div>

                        ))}

                        {/* <div className='pricing_innner_coloumn basic'>
                            <div className='pricing_inner_inner_column'>
                                <div className='pricing_name'><span>Basic</span></div>
                                <div className='week_fee'><span>$9 /</span>Week</div>
                                <p>Preferred pricing plan for small and medium organizations looking for automated long-form content.</p>
                                <ul>
                                    <li>Generate Text One Time </li>
                                    <li>No User Add</li>
                                    <li>No Social Sharing</li>
                                </ul>
                                <div className='get_start_but'>
                                    <Link to="/registration">Get Started</Link>
                                </div>
                            </div>
                        </div> */}


                        <div className='man__image'>
                            <img src={ManImage} className='man_image' width={240} />
                        </div>
                    </div>
                </Container>
                <div className='pos_abs'>
                    <img src={Grad11} width={700} className='grad_11' />
                </div>
            </div>




            <div className='testimonials_partts'>
                <Container>
                    <div className='how_works'>
                        <span>Testimonials</span>
                        <h2>What People Are Saying</h2>
                        <p>Explore our pricing plans suitable for every budget. Get exclusive benefits with your perfect subscription plan – subscribe now!</p>
                    </div>

                    <div className='react_multi_carousel'>
                        <Slider {...settings}>
                            <div className='test_inner'>
                                <div className='yellow_star'>
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                </div>
                                <p>I really like what Smart Post AI generates. I've tried a few of AIs and some of them generate lots of content, but often out of topic, or with phrases which have really no meaning. Especially for technical content.</p>
                                <div className='blog_writer d-flex align-items-center'>
                                    <div className='blogger_name'><img src={Blogger} /></div>
                                    <div className='blogger_des'>
                                        <h3>Sonam Mitra</h3>
                                        <span>Blog Writer</span>
                                    </div>
                                </div>
                            </div>
                            <div className='test_inner'>
                                <div className='yellow_star'>
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                </div>
                                <p>I really like what Smart Post AI generates. I've tried a few of AIs and some of them generate lots of content, but often out of topic, or with phrases which have really no meaning. Especially for technical content.</p>
                                <div className='blog_writer d-flex align-items-center'>
                                    <div className='blogger_name'><img src={Blogger} /></div>
                                    <div className='blogger_des'>
                                        <h3>Sonam Mitra</h3>
                                        <span>Blog Writer</span>
                                    </div>
                                </div>
                            </div>
                            <div className='test_inner'>
                                <div className='yellow_star'>
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                </div>
                                <p>I really like what Smart Post AI generates. I've tried a few of AIs and some of them generate lots of content, but often out of topic, or with phrases which have really no meaning. Especially for technical content.</p>
                                <div className='blog_writer d-flex align-items-center'>
                                    <div className='blogger_name'><img src={Blogger} /></div>
                                    <div className='blogger_des'>
                                        <h3>Sonam Mitra</h3>
                                        <span>Blog Writer</span>
                                    </div>
                                </div>
                            </div>
                            <div className='test_inner'>
                                <div className='yellow_star'>
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                </div>
                                <p>I really like what Smart Post AI generates. I've tried a few of AIs and some of them generate lots of content, but often out of topic, or with phrases which have really no meaning. Especially for technical content.</p>
                                <div className='blog_writer d-flex align-items-center'>
                                    <div className='blogger_name'><img src={Blogger} /></div>
                                    <div className='blogger_des'>
                                        <h3>Sonam Mitra</h3>
                                        <span>Blog Writer</span>
                                    </div>
                                </div>
                            </div>
                            <div className='test_inner'>
                                <div className='yellow_star'>
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                    <img src={YellowStar} />
                                </div>
                                <p>I really like what Smart Post AI generates. I've tried a few of AIs and some of them generate lots of content, but often out of topic, or with phrases which have really no meaning. Especially for technical content.</p>
                                <div className='blog_writer d-flex align-items-center'>
                                    <div className='blogger_name'><img src={Blogger} /></div>
                                    <div className='blogger_des'>
                                        <h3>Sonam Mitra</h3>
                                        <span>Blog Writer</span>
                                    </div>
                                </div>
                            </div>
                        </Slider>

                    </div>
                </Container>
                <div className='pos_abbs'>
                    <img src={TestGrad} className='test_grad' width={600} />
                </div>
            </div>


            <div className='faq_sec'>
                <Container>
                    <div className='how_works'>
                        <span>FAQ</span>
                        <h2>Frequently Asked Questions</h2>
                    </div>
                    <div className="accordion" id="accordionExample">
                        <div className="card">
                            <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseOne"
                                        aria-expanded={activeIndex === 0 ? 'true' : 'false'}
                                        onClick={() => toggleAccordion(0)}
                                    >
                                        What is Smart Post AI Content Writing Tool?
                                    </button>
                                </h2>
                            </div>

                            <div
                                id="collapseOne"
                                className={`collapse ${activeIndex === 0 ? 'show' : ''}`}
                                aria-labelledby="headingOne"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">Once you know your audience, choose a topic that will resonate with them. Look for trending topics in your industry or address common questions or challenges your audience may be facing.</div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseTwo"
                                        aria-expanded={activeIndex === 1 ? 'true' : 'false'}
                                        onClick={() => toggleAccordion(1)}
                                    >
                                        What languages does it support?
                                    </button>
                                </h2>
                            </div>

                            <div
                                id="collapseTwo"
                                className={`collapse ${activeIndex === 1 ? 'show' : ''}`}
                                aria-labelledby="headingTwo"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">Once you know your audience, choose a topic that will resonate with them. Look for trending topics in your industry or address common questions or challenges your audience may be facing.</div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseThree"
                                        aria-expanded={activeIndex === 2 ? 'true' : 'false'}
                                        onClick={() => toggleAccordion(2)}
                                    >
                                        What is SEO writing AI?
                                    </button>
                                </h2>
                            </div>

                            <div
                                id="collapseThree"
                                className={`collapse ${activeIndex === 2 ? 'show' : ''}`}
                                aria-labelledby="headingThree"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">Once you know your audience, choose a topic that will resonate with them. Look for trending topics in your industry or address common questions or challenges your audience may be facing.</div>
                            </div>
                        </div>



                        <div className="card">
                            <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseThree"
                                        aria-expanded={activeIndex === 3 ? 'true' : 'false'}
                                        onClick={() => toggleAccordion(3)}
                                    >
                                        How to use SEO writing AI?
                                    </button>
                                </h2>
                            </div>

                            <div
                                id="collapseThree"
                                className={`collapse ${activeIndex === 3 ? 'show' : ''}`}
                                aria-labelledby="headingThree"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">Once you know your audience, choose a topic that will resonate with them. Look for trending topics in your industry or address common questions or challenges your audience may be facing.</div>
                            </div>
                        </div>
                        <div className='accordion_grad'>
                            <img src={AccordionGrad} className='accordion_grad_parttts' width={600} />
                        </div>
                    </div>
                </Container>

            </div>
        </div>
    );
};

export default Home;