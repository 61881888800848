import React, { useState, useEffect, useRef } from "react";
import "./ArticleGenerateNewDesign.css";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import axios from "../../../axios/axios";
import message from "../../../messages";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Audio } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faXTwitter,
  faWordpressSimple,
} from "@fortawesome/free-brands-svg-icons";
import {
  faArrowLeft,
  faCheckCircle,
  faXmark,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

import Moment from "moment";
import {
  FacebookSocial,
  // InsSocial,
  // TwitterSocial,
  // LinkedSocial,
  Wp,
  FbLogo,
  Toggle,
  Information,
} from "../../../assets/images";
// import Select from "react-select";
// import { faInfo } from "@fortawesome/free-solid-svg-icons";
import CustomTooltip from "../../Dashboard/customTooltip/custom-tooltip";
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  // Container,
  // Row,
  // Col,
  Modal,
  Button,
  Form,
  // Dropdown,
  // Table,
  // Accordion,
  // Card,
  // Tab,
  // Nav,
  OverlayTrigger,
  Tooltip as BootstrapTooltip,
} from "react-bootstrap";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
const roundTimeOptions = [
  "12:00 AM",
  "12:30 AM",
  "1:00 AM",
  "1:30 AM",
  "2:00 AM",
  "2:30 AM",
  "3:00 AM",
  "3:30 AM",
  "4:00 AM",
  "4:30 AM",
  "5:00 AM",
  "5:30 AM",
  "6:00 AM",
  "6:30 AM",
  "7:00 AM",
  "7:30 AM",
  "8:00 AM",
  "8:30 AM",
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",

  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "5:30 PM",
  "6:00 PM",
  "6:30 PM",
  "7:00 PM",
  "7:30 PM",
  "8:00 PM",
  "8:30 PM",
  "9:00 PM",
  "9:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

const ArticleGenerateNewDesign = () => {
  const [value, setValue] = useState(30);
  const [valueErr, setValueErr] = useState("");
  const [prompt, setPrompt] = useState(false);
  const [yourPrompt, setYourPrompt] = useState("");
  const [promptId, setPromptId] = useState("");
  const [selectTone, setSelectTone] = useState(null);
  const [yourPromptErr, setYourPromptErr] = useState("");
  const [postInfo, setPostInfo] = useState([]);
  const [loader, setLoader] = useState(false);
  const [promptData, setPromptData] = useState([]);
  const [platform, setPlatform] = useState("");
  const [noOfPost, setNoOfPost] = useState(1);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [selectedCheckboxValue, setSelectedCheckboxValue] = useState(null);
  const [step, setStep] = useState(1);
  const textAreaRef = useRef(null);
  const tooltip =
    "If you want to post in your Profile and groups, Please copy the text and share it manually";
  const artGenTooltip = "Generated posts take 1 use each";
  const params = useParams();
  let today = Moment(new Date()).format("YYYY-MM-DD");
  const [selectedImage, setSelectedImage] = useState([]);
  const [img, setimg] = useState([]);
  const [startDate, setStartDate] = useState(""); /////////////
  const [dateError, setDateError] = useState(""); ///////////
  const [time, setTime] = useState(""); ///////////////
  const [timeError, setTimeError] = useState("");
  const [dragImg, setDragImg] = useState(null);
  const [connectedAcc, setConnectedAcc] = useState([]);
  const [selectedAcc, setSelectedAcc] = useState([]);
  const [wpData, setWpData] = useState({
    url: "",
    app_id: "",
    app_password: "",
  });
  const [credsError, setCredsError] = useState({
    urlErr: "",
    appIdErr: "",
    appPassErr: "",
  });
  const [ckEditorData, setCkEditorData] = useState(null);
  const [ckEditorDataErr, setCkEditorDataErr] = useState("");
  const [selectedCatValue, setSelectedCatValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isCollapsed1, setIsCollapsed1] = useState(false);
  // const [wordCount, setWordCount] = useState("");
  const [ckEditorDataCount, setCkEditorDataCount] = useState(0);
  const [isCopied, setIsCopied] = useState(false);
  const [dynamicTones, setDynamicTones] = useState([]);
  const [buyPlansModal, setBuyPlansModal] = useState(false);
  const [resError, setResError] = useState("");

  const [authUserData, setauthUserData] = useState(
    localStorage.getItem("AuthUserData")
      ? JSON.parse(localStorage.getItem("AuthUserData"))
      : null
  );

  const textCopy = (data) => {
    navigator.clipboard.writeText(data);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const contentValidate = () => {
    setCkEditorDataErr("");
    if (ckEditorData == null || ckEditorData == "") {
      setCkEditorDataErr("The content field is required");
      return false;
    }
    return true;
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleCheckboxChanging = (index) => {
    const isChecked = selectedCheckbox === index;

    if (isChecked) {
      setSelectedCheckbox(null);
      setSelectedCheckboxValue(null);
    } else {
      setSelectedCheckbox(index);
      setSelectedCheckboxValue(postInfo[index]);
      setCkEditorData(postInfo[index].content);
    }
  };

  const handlegetPrompt = async (platform) => {
    setPlatform(platform);
    setPrompt(true);
    await axios
      .post(`/prompts`, { platform })
      .then((res) => {
        setLoader(false);
        setPromptData(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };
  const validate = () => {
    setYourPromptErr("");
    setValueErr("");
    if (!yourPrompt) {
      setYourPromptErr("Please select a media and choose your prompt");
      return false;
    }
    if (value === 0) {
      setValueErr("Please select your content length");
      return false;
    }

    return true;
  };

  const toggleCollapse = () => {
    setIsCollapsed1(!isCollapsed1);
    setIsExpanded1(!isExpanded1);
  };

  const generateArticle = (e) => {
    e.preventDefault();

    if (validate()) {
      let paramsData = {
        prompt_id: promptId,
        prompt: yourPrompt,
        tone: selectTone,
        no_of_words: value,
        platform: platform,
        no_of_post: parseInt(noOfPost),
      };
      // Submit the form or perform other actions
      setLoader(true);
      axios
        .post("/content-generate", paramsData, { timeout: 120000 })
        .then((res) => {
          setLoader(false);
          setResError(res.data.message);
          if (res.data.status === true) {
            let CreditPoint = localStorage.getItem("CreditDataBalance");
            CreditPoint = CreditPoint - noOfPost;
            localStorage.setItem("CreditDataBalance", CreditPoint);
            setPostInfo(res.data.data.post_info);
          } else {
            setBuyPlansModal(true);
          }
        })
        .catch((err) => {
          setLoader(false);
          message.error("Please select a media channel and choose prompt");
          //console.log("err", err);
        });
    }
  };
  const checkedConnAc = (ac_Type) => {
    return connectedAcc.filter((ac) => ac.account_type === ac_Type);
  };

  const handlePostPublish = async () => {
    if (selectedAcc.length !== 0) {
      let paramsData = {
        title: selectedCheckboxValue.title,
        content: ckEditorData ? ckEditorData : selectedCheckboxValue.content,
        images: !dragImg ? selectedImage : URL.createObjectURL(dragImg),
        facebook_ids: selectedAcc
          .filter((account) => account.account_type === "Facebook")
          .map((account) => account.id),
        twitter_ids: selectedAcc
          .filter((account) => account.account_type === "Twitter")
          .map((account) => account.id),
        instagram_ids: selectedAcc
          .filter((account) => account.account_type === "Instagram")
          .map((account) => account.id),
        linkedin_ids: selectedAcc
          .filter((account) => account.account_type === "LinkedIn")
          .map((account) => account.id),
        wordpress_ids: selectedAcc
          .filter((account) => account.account_type === "WordPress")
          .map((account) => account.id),
        wp_category: selectedCatValue,
      };
      if (params.postId) {
        paramsData.postId = params.postId;
      }
      setLoader(true);
      await axios
        .post("/post-publish", paramsData)
        .then((res) => {
          if (res.status === 200) {
            setLoader(false);
            message.success("Post published successfully");
          }
        })
        .catch((err) => {
          setLoader(false);
          message.error("Internal server error");
        });
    } else {
      message.error("Please select a media channel");
    }
  };

  // const handlePostPublish = async () => {
  //   if (selectedAcc.length !== 0) {
  //     let formData = new FormData();

  //     formData.append("title", [selectedCheckboxValue.title]);
  //     formData.append(
  //       "content",
  //       [ckEditorData ? ckEditorData : selectedCheckboxValue.content]
  //     );

  //     // Append images based on conditions
  //     if (!dragImg) {
  //       formData.append("images", selectedImage);
  //     } else {
  //       formData.append("images", [dragImg]);
  //     }

  //     // Append account IDs based on account type
  //     selectedAcc.forEach((account) => {
  //       switch (account.account_type) {
  //         case "Facebook":
  //           formData.append("facebook_ids", [account.id]);
  //           break;
  //         case "Twitter":
  //           formData.append("twitter_ids", [account.id]);
  //           break;
  //         case "Instagram":
  //           formData.append("instagram_ids", [account.id]);
  //           break;
  //         case "LinkedIn":
  //           formData.append("linkedin_ids", [account.id]);
  //           break;
  //         case "WordPress":
  //           formData.append("wordpress_ids", [account.id]);
  //           break;
  //         default:
  //           break;
  //       }
  //     });

  //     formData.append("wp_category", [selectedCatValue]);

  //     if (params.postId) {
  //       formData.append("postId", [params.postId]);
  //     }
  //     setLoader(true);
  //     await axios
  //       .post("/post-publish", formData)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           setLoader(false);
  //           message.success("Post published successfully");
  //         }
  //       })
  //       .catch((err) => {
  //         setLoader(false);
  //         message.error("Internal server error");
  //       });
  //   } else {
  //     message.error("Please select a media channel");
  //   }
  // };

  const DateAndTimeValidate = () => {
    setDateError("");
    setTimeError("");

    if (!startDate) {
      setDateError("Date field is required");
      return false;
    } else if (startDate < today) {
      setDateError("Please select a date that is today or in the future");
      return false;
    }
    if (!time) {
      setTimeError("Time field is required");
      return false;
    }

    return true;
  };
  const handleSchedule = async (e) => {
    e.preventDefault();
    if (DateAndTimeValidate() && selectedAcc.length !== 0) {
      let paramsData = {
        title: selectedCheckboxValue.title,
        content: ckEditorData ? ckEditorData : selectedCheckboxValue.content,
        images: !dragImg ? selectedImage : URL.createObjectURL(dragImg),
        schedule_at: `${startDate} ${time}`,
        facebook_ids: selectedAcc
          .filter((account) => account.account_type === "Facebook")
          .map((account) => account.id),
        twitter_ids: selectedAcc
          .filter((account) => account.account_type === "Twitter")
          .map((account) => account.id),
        instagram_ids: selectedAcc
          .filter((account) => account.account_type === "Instagram")
          .map((account) => account.id),
        linkedin_ids: selectedAcc
          .filter((account) => account.account_type === "LinkedIn")
          .map((account) => account.id),
        wordpress_ids: selectedAcc
          .filter((account) => account.account_type === "WordPress")
          .map((account) => account.id),
        wp_category: selectedCatValue,
      };

      if (params.postId) {
        paramsData.postId = params.postId;
      }
      // Submit the form or perform other actions
      setLoader(true);
      await axios
        .post("/post-schedule", paramsData)
        .then((res) => {
          setLoader(false);
          message.success(res.data.message);
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    } else {
      message.error("Please select a media channel");
    }
  };

  const countData = () => {
    const typingData = ckEditorData?.split(" ")?.length;
    // const respContentCount = selectedCheckboxValue?.content?.split(" ")?.length;
    // setWordCount(respContentCount);
    setCkEditorDataCount(typingData);
  };

  const sideBarCollapsed = () => {
    const appElement = document.getElementById("app");
    if (postInfo !== "" || selectedCheckboxValue == null) {
      appElement.classList.add("sideBarCollapsed");
    } else {
      appElement.classList.remove("sideBarCollapsed");
    }
  };

  const genImgDelete = () => {
    setimg([]);
  };
  const dragImgDelete = () => {
    setDragImg(null);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleWpChange = (event) => {
    const { name, value } = event.target;
    setWpData({ ...wpData, [name]: value });
  };

  const handleTimeChange = (event) => {
    const newTime = event.target.value;
    setTime(newTime);
  };

  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.value;
    const isChecked = selectedImage.includes(checkboxValue);

    if (isChecked) {
      // If the checkbox value is already in the array, remove it to uncheck
      setSelectedImage(
        selectedImage.filter((value) => value !== checkboxValue)
      );
    } else {
      // If the checkbox value is not in the array, add it to check
      setSelectedImage([...selectedImage, checkboxValue]);
    }
  };

  const getAllSocialAccount = async () => {
    await axios
      .get("/social/accounts")
      .then((res) => {
        const instaAcc = res.data.data;
        const filterAc = instaAcc.filter(
          (iAc) =>
            iAc.account_type === "LinkedIn" ||
            iAc.account_type === "Facebook" ||
            iAc.account_type === "WordPress" ||
            iAc.account_type === "Instagram" ||
            iAc.account_type === "Twitter"
        );
        // console.log("filterAc", filterAc)
        setConnectedAcc(filterAc);
      })
      .catch((err) => {
        // console.error(err, "<<-- Error in get all social accounts")
      });
  };

  const handleToneSelect = (tone_name) => {
    // If the selected tone is already active, unselect it
    const newTone = selectTone === tone_name ? null : tone_name;
    setSelectTone(newTone);
  };

  const handleAccChange = async (e, accountId, account_type) => {
    const isChecked = e.target.checked;
    // Check if the account with the provided id exists in the selectedAcc array
    const existingAccountIndex = selectedAcc.findIndex(
      (account) => account.id === accountId
    );

    if (existingAccountIndex !== -1) {
      // Account exists, so update its selected property
      setSelectedAcc(selectedAcc.filter((acc) => acc.id !== accountId));
    } else {
      // Account does not exist, so add the new account data
      setSelectedAcc((prevSelectedAcc) => [
        ...prevSelectedAcc,
        { id: accountId, account_type: account_type, selected: isChecked },
      ]);
    }
  };

  const facebookAuth = async () => {
    setLoader(true);
    await axios
      .get("/facebook/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_blank");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const instagramAuth = async () => {
    setLoader(true);
    await axios
      .get("/instagram/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_self");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const twitterAuth = async () => {
    setLoader(true);
    await axios
      .get("/twitter/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_blank");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const disconnectSocialMedia = async (e, acc_type) => {
    const findClickAc = connectedAcc.filter((acc) => {
      return acc.account_type === acc_type;
    });
    const Ac_Id = findClickAc[0].accounts[0].id;

    let paramsData = {
      type: acc_type,
      account_id: Ac_Id,
    };
    setLoader(true);
    await axios
      .post("/disconnect-social-account", paramsData)
      .then((res) => {
        setLoader(false);
        message.success("Disconnected");
        getAllSocialAccount();
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const linkedinAuth = async () => {
    setLoader(true);
    await axios
      .get("/linkedin/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_blank");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };
  const wordpressAuth = async () => {
    setLoader(true);

    if (!wpData.app_id) {
      setCredsError({ ...credsError, appIdErr: "AppId is required" });
      return;
    }
    if (!wpData.url) {
      setCredsError({ ...credsError, urlErr: "Url is required" });
      return;
    }
    if (!wpData.app_password) {
      setCredsError({ ...credsError, appPassErr: "AppId is required" });
      return;
    } else {
      await axios
        .post("/wordpress/auth", wpData)
        .then((res) => {
          setLoader(false);
          handleCloseModal();
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }
  };

  const handleImgSubmit = async (e) => {
    e.preventDefault();

    let paramsData = {
      title: selectedCheckboxValue.title,
      no_of_images: 5,
    };
    setLoader(true);
    await axios
      .post("/image-generate", paramsData)
      .then((res) => {
        setLoader(false);
        setimg(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  // const handleEditorChange = (event, editor) => {
  //   const data = editor.getData();
  //   setCkEditorData(data);
  // };

  const nextStep = (e) => {
    e.preventDefault();
    if (step === 1) {
      if (selectedCheckbox !== null) {
        setStep((prevStep) => prevStep + 1);
      } else {
        message.error("Please select a content");
      }
    } else if (step === 2) {
      if (contentValidate()) {
        setStep((prevStep) => prevStep + 1);
      }
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const getAllTones = async () => {
    setLoader(true);
    await axios
      .get("/tones")
      .then((res) => {
        setLoader(false);
        setDynamicTones(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.error(err, "<<-- Error");
      });
  };

  const handlePromptSelect = (e, data) => {
    setPromptId(data._id);
    setYourPrompt(data.description);
  };

  useEffect(() => {
    isCopied == true && message.success("Copied Successfully");
    countData();
    getAllSocialAccount();
    sideBarCollapsed();
    getAllTones();
  }, [ckEditorData, selectedCheckboxValue, isCopied]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            {loader == true && (
              <div className="loader-wrapper">
                <Audio
                  height="80"
                  width="80"
                  radius="9"
                  color="green"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              </div>
            )}
            <div className="dash_inner_partts">
              <DashboardLeftPanel
                selectedCheckboxValue={selectedCheckboxValue}
                postInfo={postInfo}
              />
              <div className="dash_right_panel">
                <div className="dash_right_inner_panel">
                  <div className="row">
                    <div className="col-lg-9 col-md-12 col-sm-12">
                      <div className="promt_templates">
                        <div className="row">
                          <div className="col-md-5">
                            <h2 className="title_md">Social Channels</h2>
                            <div
                              className="prompt_list"
                              style={{
                                display: prompt === true ? "none" : "block",
                              }}
                            >
                              <div className="row prompt_temp_row">
                                <div className="col-md-4">
                                  <div
                                    className="prompt_temp_item text-center mb-3"
                                    onClick={() => handlegetPrompt("Facebook")}
                                  >
                                    <FontAwesomeIcon icon={faFacebookF} />
                                    <h4 className="mb-0 mt-2">Facebook</h4>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div
                                    className="prompt_temp_item text-center mb-3"
                                    onClick={() => handlegetPrompt("WordPress")}
                                  >
                                    <FontAwesomeIcon icon={faWordpressSimple} />
                                    <h4 className="mb-0 mt-2">WordPress</h4>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="prompt_temp_item text-center mb-3 pt_more">
                                    <h4 className="mb-0 mt-2">
                                      More Coming Soon
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {prompt === true ? (
                              <div className="prompt_result">
                                <div className="prompt_result_head">
                                  <div
                                    className="pr_back"
                                    onClick={() => setPrompt(false)}
                                  >
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                  </div>
                                  {platform}
                                </div>
                                <div className="prompt_result_body">
                                  <ul className="list-unstyled list_pr">
                                    {promptData?.map((item) => (
                                      <>
                                        <h4 className="title_legend">
                                          {item?.type}
                                        </h4>
                                        {item.values.map((value) => (
                                          <li
                                            key={value._id}
                                            onClick={(e) =>
                                              handlePromptSelect(e, value)
                                            }
                                          >
                                            {value.description}
                                          </li>
                                        ))}
                                      </>
                                    ))}
                                  </ul>
                                  {/* <ul className="list-unstyled list_pr">
                                    {promptData?.map((item) => (
                                      <li
                                        key={item.description}
                                        onClick={(e) =>
                                          handlePromptSelect(e, item)
                                        }
                                      >
                                        {item.description}
                                      </li>
                                    ))}
                                  </ul> */}
                                  {/* <h4 className="title_legend mt-5">Technology</h4>
                                  <ul className="list-unstyled list_pr">
                                    {promptData?.map((item) => (
                                      <li
                                        key={item.description}
                                        onClick={(e) =>
                                          handlePromptSelect(e, item)
                                        }
                                      >
                                        {item.description}
                                      </li>
                                    ))}
                                  </ul> */}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-7">
                            <div className="title_bar">
                              <h2 className="title_sm">Your Prompt</h2>
                              <div className="titlebar_tools"></div>
                            </div>

                            <div className="enter_prompt_area mb-4">
                              <textarea
                                className="promt_textarea form-control"
                                value={yourPrompt}
                                placeholder="For topic suggestions, select a prompt template from the left."
                                onChange={(e) => setYourPrompt(e.target.value)}
                                disabled={!yourPrompt ? true : false}
                              ></textarea>
                              {yourPromptErr && (
                                <p style={{ color: "red", fontSize: "14px" }}>
                                  {yourPromptErr}
                                </p>
                              )}
                              {/* <div className="epa_footer">
                          <span className="badge_primary">936 left</span>
                        </div> */}
                            </div>

                            <h4 className="title_sm">Tone of Voice</h4>
                            <div className="tov mb-4">
                              <ul className="list-inline">
                                {dynamicTones?.map((tones) => (
                                  <li
                                    key={tones.name}
                                    className={`list-inline-item mb-2 ${
                                      selectTone === tones.name
                                        ? "active_tone"
                                        : ""
                                    }`}
                                    onClick={() => handleToneSelect(tones.name)}
                                  >
                                    {tones.name}
                                  </li>
                                ))}
                              </ul>
                            </div>

                            <div className="title_bar">
                              <h4 className="title_sm">Approximate Words</h4>
                              <div className="titlebar_tools">
                                <span className="ap_count">{value}</span>
                              </div>
                            </div>
                            {platform == "WordPress" ? (
                              <div className="apw mb-4">
                                <Slider
                                  value={value}
                                  onChange={handleChange}
                                  min={0}
                                  max={1000}
                                />
                              </div>
                            ) : (
                              <div className="apw mb-4">
                                <Slider
                                  value={value}
                                  onChange={handleChange}
                                  min={0}
                                  max={100}
                                />
                              </div>
                            )}
                            {valueErr && (
                              <p style={{ color: "red", fontSize: "14px" }}>
                                {valueErr}
                              </p>
                            )}

                            <div className="title_bar justify-content-start align-items-center mb-5">
                              <h4 className="mb-0 title_sm">
                                Posts To Generate
                              </h4>
                              <div className="titlebar_tools ml-3">
                                <select
                                  className="form-control"
                                  value={noOfPost}
                                  onChange={(e) => setNoOfPost(e.target.value)}
                                >
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                  <option value={3}>3</option>
                                </select>
                              </div>
                              <div className="ml-2">
                                <OverlayTrigger
                                  overlay={
                                    <BootstrapTooltip>
                                      {artGenTooltip}
                                    </BootstrapTooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    style={{ fontSize: 20 }}
                                  />
                                </OverlayTrigger>
                              </div>
                            </div>

                            <div className="gen_art_btn d-flex align-items-start">
                              <button
                                className="btn btn_primary mr-2"
                                onClick={generateArticle}
                              >
                                {postInfo.length !== 0
                                  ? "Re-generate "
                                  : "Generate "}
                              </button>
                              <div className="remaining_uses">
                                <div className="ru_text">
                                  <div className="ru_title">
                                    {localStorage.getItem("CreditDataBalance")
                                      ? localStorage.getItem(
                                          "CreditDataBalance"
                                        )
                                      : "0"}{" "}
                                    Remaining uses available
                                  </div>
                                </div>
                              </div>
                            </div>

                            <Modal show={buyPlansModal} centered size="sm">
                              <Modal.Header>
                                <Modal.Title as="h5">
                                  Generate Article Info
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>{resError}</Modal.Body>
                              <Modal.Footer>
                                <Link
                                  to="/plan"
                                  className="btn_primary"
                                  onClick={() => {}}
                                >
                                  Upgrade Plan
                                </Link>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pr-0 col-lg-3 col-md-12 col-sm-12">
                      <div className="prompt_rightbar">
                        {postInfo.length == 0 && (
                          <div>
                            <h2 className="title_sm">Get Started</h2>
                            <ol className="pl-3 gs_list mb-4">
                              <li className="mb-2">
                                Pick a prompt from the left-hand side "Prompt
                                templates" panel, use the button to get a random
                                prompt, or write one from scratch.
                              </li>
                              <li className="mb-2">
                                Write or edit your prompt with what you want the
                                Al to generate and hit the Generate button.
                              </li>
                              <li className="mb-2">
                                Select the results you like and hit the Share on
                                social button.
                              </li>
                            </ol>

                            {/* <div className="remaining_uses d-flex mt-5">
                              <div className="ru_icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width={25} height={25}><path opacity=".4" d="M12 14.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM18.5 9.5v5" stroke="#3f3eed" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M5 22a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" stroke="#3f3eed" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.25 16.75v.93c0 .35-.18.68-.49.86L4 19" stroke="#3f3eed" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M2 15.2V9c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v6c0 3.5-2 5-5 5H8.5" stroke="#3f3eed" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
                              </div>
                              <div className="ru_text">
                                  <div className='ru_time'>{localStorage.getItem("CreditDataBalance") ? localStorage.getItem("CreditDataBalance") : "0"}
                                  </div>
                                  <div className='ru_title'>Remaining uses available</div>
                              </div>
                            </div> */}
                          </div>
                        )}
                        {postInfo.length !== 0 && (
                          <h2 className="title_sm mb-0">Results</h2>
                        )}
                        {postInfo.length !== 0 && (
                          <div className="sub_heading mb-3">
                            {postInfo.length} Results In this feed
                          </div>
                        )}
                        <div className="prompt_text_result">
                          {postInfo.map((item, index) => (
                            <div className="text_result_card mb-3">
                              <label
                                className="text_result_card_body mb-3"
                                key={index}
                              >
                                <input
                                  type="checkbox"
                                  checked={selectedCheckbox === index}
                                  onChange={() => handleCheckboxChanging(index)}
                                />
                                <div className="pr_checkmark">
                                  <FontAwesomeIcon icon={faCheckCircle} />
                                </div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.content.replace(
                                      /\n/g,
                                      "<br />"
                                    ),
                                  }}
                                ></div>
                              </label>
                              <button
                                className="btn btn_primary_outline btn-block"
                                type="button"
                                onClick={() => textCopy(item.content)}
                              >
                                Copy
                              </button>
                            </div>
                          ))}
                        </div>
                        {postInfo.length !== 0 && (
                          <button
                            className="btn btn_primary"
                            onClick={nextStep}
                          >
                            Share on social
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <div className="dash_inner_partts">
              {loader == true && (
                <div className="loader-wrapper">
                  <Audio
                    height="80"
                    width="80"
                    radius="9"
                    color="green"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                  />
                </div>
              )}
              <DashboardLeftPanel
                selectedCheckboxValue={selectedCheckboxValue}
                postInfo={postInfo}
              />
              <div className="dash_right_panel">
                <div className="dash_right_inner_panel">
                  <div className="edit_art_top">
                    <h3>Edit your article</h3>
                    <div className="text-right mb-3">
                      <span>
                        No. of Word: {ckEditorDataCount}
                        {/* {ckEditorData !== "" ? ckEditorDataCount : wordCount} */}
                      </span>
                    </div>

                    <div className="ckeditor_partts">
                      {/* <CKEditor
                        editor={ClassicEditor}
                        onChange={handleEditorChange}
                        data={selectedCheckboxValue.content}
                      /> */}
                      <textarea
                        className="promt_textarea form-control h-auto"
                        name="ckeditor"
                        value={ckEditorData}
                        rows="10"
                        onChange={(e) => setCkEditorData(e.target.value)}
                      ></textarea>
                      {ckEditorDataErr && (
                        <p style={{ color: "red", fontSize: "14px" }}>
                          {ckEditorDataErr}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="generate_images">
                    {!img.length > 0 && <h3>Choose Images</h3>}
                    {!img.length > 0 && (
                      <div className="generate_images_inner">
                        <input
                          type="file"
                          name="imagefile"
                          accept="image/*"
                          onChange={(event) => {
                            setDragImg(event.target.files[0]);
                          }}
                        />
                      </div>
                    )}
                    {dragImg != null && (
                      <div className="row mt-4">
                        <div className="col-md-3">
                          <div className="article_image_preview">
                            <button
                              className="btn btn_danger btn_floating_remove"
                              onClick={dragImgDelete}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </button>
                            <img
                              src={URL.createObjectURL(dragImg)}
                              alt="Selected"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="generate_images_partts">
                      <div className="generate_images_inner_partts">
                        {!dragImg && <h3>Generate Images</h3>}

                        {!dragImg && (
                          <button
                            variant="primary"
                            type="button"
                            // disabled={!imgTopic}
                            className="btn btn_primary mt-3"
                            onClick={handleImgSubmit}
                          >
                            {img.length > 0 ? "Re-Generate" : "Generate"}
                          </button>
                        )}

                        <div className="generated_images">
                          <div className="generated_image_inner">
                            {img.map((item, key) => {
                              return (
                                <label
                                  key={item.url}
                                  className="labl2"
                                  for={key}
                                >
                                  <button
                                    onClick={genImgDelete}
                                    className="btn btn_danger btn_floating_remove"
                                  >
                                    x
                                  </button>
                                  <input
                                    type="checkbox"
                                    name={key}
                                    value={item.url}
                                    id={key}
                                    checked={selectedImage.includes(item.url)}
                                    onChange={handleCheckboxChange}
                                  />
                                  <div>
                                    <img
                                      src={item.url || item}
                                      className="img-fluid"
                                    />
                                  </div>
                                </label>
                              );
                            })}
                          </div>
                        </div>

                        <div className="generate_buttons">
                          <button
                            type="button"
                            className="btn btn_primary mr-3"
                            onClick={prevStep}
                          >
                            Back
                          </button>
                          <button
                            type="button"
                            className="btn btn_primary"
                            onClick={nextStep}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <div className="dash_inner_partts">
              {loader == true && (
                <div className="loader-wrapper">
                  <Audio
                    height="80"
                    width="80"
                    radius="9"
                    color="green"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                  />
                </div>
              )}
              <DashboardLeftPanel
                selectedCheckboxValue={selectedCheckboxValue}
                postInfo={postInfo}
              />
              <div className="dash_right_panel">
                <div className="dash_right_inner_panel">
                  <div className="prf_parttts" id="scrollTargetRef">
                    <div className="prf_textts">
                      <h4 className="" style={{ color: "white" }}>
                        Connect your social media account :
                      </h4>
                    </div>
                    <div className="social_connect_inner_sec social__connect_inner_parttts">
                      <div className="social_inner_inner_sec">
                        {platform == "WordPress" ? (
                          <div className="social_inner_inner_sec">
                            <div className="social_white_sec">
                              <img src={Wp} width={35} />
                              <h4>Wordpress Blog</h4>
                              <span>Profile</span>
                              <button
                                type="button"
                                className={
                                  checkedConnAc("WordPress").length > 0
                                    ? "dis_button"
                                    : "connect_button"
                                }
                                onClick={(e) =>
                                  checkedConnAc("WordPress").length > 0
                                    ? disconnectSocialMedia(e, "WordPress")
                                    : handleShowModal()
                                }
                              >
                                {checkedConnAc("WordPress").length > 0
                                  ? "Disconnect"
                                  : "Connect"}
                              </button>
                              <Modal
                                show={showModal}
                                onHide={handleCloseModal}
                                size="lg"
                                centered
                              >
                                <Modal.Header className="d-block">
                                  <Modal.Title className="text-center d-block">
                                    Wordpress Login
                                  </Modal.Title>

                                  <Button
                                    variant="secondary"
                                    className="wp_close_modal"
                                    onClick={handleCloseModal}
                                  >
                                    X
                                  </Button>
                                </Modal.Header>
                                <Modal.Body>
                                  <div className="d-flex justify-content-between flex-wrap">
                                    <div
                                      className={`login_wp_partts px-4 w-50 ${
                                        isExpanded1 ? "expanded" : ""
                                      }`}
                                    >
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label>
                                          Url
                                          <CustomTooltip text="This Will be your blog URL.">
                                            <span className="custom_tooltip">
                                              <img src={Information} />
                                            </span>
                                          </CustomTooltip>
                                        </Form.Label>
                                        <Form.Control
                                          name="url"
                                          type="text"
                                          className="emailfield"
                                          placeholder="Enter url"
                                          onChange={handleWpChange}
                                        />
                                        <p style={{ color: "red" }}>
                                          {credsError.urlErr}
                                        </p>
                                      </Form.Group>

                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label>App Id</Form.Label>
                                        <CustomTooltip text="Please Visit your User tab under admin panel.">
                                          <span className="custom_tooltip">
                                            <img src={Information} />
                                          </span>
                                        </CustomTooltip>
                                        <Form.Control
                                          name="app_id"
                                          type="text"
                                          className="emailfield"
                                          placeholder="Enter app id"
                                          onChange={handleWpChange}
                                        />
                                        <p style={{ color: "red" }}>
                                          {credsError.appIdErr}
                                        </p>
                                      </Form.Group>

                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicPassword"
                                      >
                                        <Form.Label>
                                          Password
                                          <CustomTooltip text="Generate App password under user tab in admin panel.">
                                            <span className="custom_tooltip">
                                              <img src={Information} />
                                            </span>
                                          </CustomTooltip>
                                        </Form.Label>
                                        <Form.Control
                                          name="app_password"
                                          type="password"
                                          className="emailfield"
                                          placeholder="Enter password"
                                          onChange={handleWpChange}
                                        />
                                        <p style={{ color: "red" }}>
                                          {credsError.appPassErr}
                                        </p>
                                      </Form.Group>
                                      <Button
                                        variant="primary"
                                        type="submit"
                                        className="wp_submit"
                                        onClick={wordpressAuth}
                                      >
                                        Submit
                                      </Button>

                                      <button
                                        onClick={toggleCollapse}
                                        className="toggle__button1"
                                      >
                                        <img src={Toggle} />
                                      </button>
                                    </div>

                                    {isCollapsed1 ? null : (
                                      <div
                                        className={`w-50 wp_procedure px-4  ${
                                          isCollapsed1 ? "collapsed" : ""
                                        }`}
                                      >
                                        <h5 class="mb-3">
                                          To connect your WordPress site, please
                                          follow these steps:
                                        </h5>
                                        <ol>
                                          <li class="mb-2">
                                            Login to your WordPress Admin
                                            Dashboard where you want to publish
                                            your content.
                                          </li>
                                          <li class="mb-2">
                                            Proceed to the "Users" section
                                            located within the left main
                                            navigation page.
                                          </li>
                                          <li class="mb-2">
                                            Select the User account that you
                                            intend to use for publishing.
                                          </li>
                                          <li class="mb-2">
                                            After selecting User, go to Profile
                                            and navigate to the “Application
                                            Password” section.
                                          </li>
                                          <li class="mb-2">
                                            You need to enter name in the field
                                            “New Application Password Name”.
                                          </li>
                                          <li class="mb-2">
                                            Now click on “Add New Application
                                            Password” for password generation.
                                          </li>
                                          <li class="mb-2">
                                            After clicking, your Application
                                            Password will be generated.
                                          </li>
                                          <li class="mb-2">
                                            Now fill up the details - WordPress
                                            URL, Application ID, and Application
                                            Password in the form on the left.
                                          </li>
                                          <li class="mb-2">
                                            Lastly, click on “Connect” to
                                            complete the process and your
                                            WordPress site will be connected.
                                          </li>
                                        </ol>
                                      </div>
                                    )}
                                  </div>
                                </Modal.Body>
                              </Modal>
                            </div>
                          </div>
                        ) : (
                          <div className="social_white_sec">
                            <img src={FacebookSocial} width={35} />
                            <h4>
                              Facebook{" "}
                              <OverlayTrigger
                                overlay={
                                  <BootstrapTooltip>{tooltip}</BootstrapTooltip>
                                }
                              >
                                <FontAwesomeIcon icon={faCircleInfo} />
                              </OverlayTrigger>
                            </h4>
                            <span>Pages</span>
                            <button
                              type="button"
                              className={
                                checkedConnAc("Facebook").length > 0
                                  ? "dis_button"
                                  : "connect_button"
                              }
                              onClick={(e) =>
                                checkedConnAc("Facebook").length > 0
                                  ? disconnectSocialMedia(e, "Facebook")
                                  : facebookAuth()
                              }
                            >
                              {checkedConnAc("Facebook").length > 0
                                ? "Disconnect"
                                : "Connect"}
                            </button>
                          </div>
                        )}
                      </div>
                      {/* <div className="social_inner_inner_sec">
                <div className="social_white_sec">
                  <img src={InsSocial} width={35} />
                  <h4>Instagram</h4>
                  <span>Profile</span>
                  <button
                    type="button"
                    className="connect_button"
                    onClick={instagramAuth}
                  >
                    Connect
                  </button>
                </div>
              </div>

              <div className="social_inner_inner_sec">
                <div className="social_white_sec">
                  <img src={TwitterSocial} width={35} />
                  <h4>Twitter</h4>
                  <span>Profile</span>
                  <button
                    type="button"
                    className="connect_button"
                    onClick={twitterAuth}
                  >
                    Connect
                  </button>
                </div>
              </div>
              <div className="social_inner_inner_sec">
                <div className="social_white_sec">
                  <img src={LinkedSocial} width={35} />
                  <h4>Linkedin</h4>
                  <span>Profile</span>
                  <button
                    type="button"
                    className="connect_button"
                    onClick={linkedinAuth}
                  >
                    Connect
                  </button>
                </div>
              </div>

              <div className="social_inner_inner_sec">
                <div className="social_white_sec">
                  <img src={Wp} width={35} />
                  <h4>Wordpress Blog</h4>
                  <span>Profile</span>
                  <button
                    type="button"
                    className="connect_button"
                    onClick={handleShowModal}
                  >
                    Connect
                  </button>
                  <Modal
                    show={showWpModal}
                    onHide={handleCloseModal}
                    size="lg"
                    centered
                  >
                    <Modal.Header className="d-block">
                      <Modal.Title className="text-center d-block">
                        Wordpress Login
                      </Modal.Title>

                      <Button
                        variant="secondary"
                        className="wp_close_modal"
                        onClick={handleCloseModal}
                      >
                        X
                      </Button>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="login_wp_partts">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Url</Form.Label>
                          <Form.Control
                            name="url"
                            type="text"
                            className="emailfield"
                            placeholder="Enter url"
                            onChange={handleWpChange}
                          />
                          <p style={{ color: "red" }}>{credsError.urlErr}</p>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>App Id</Form.Label>
                          <Form.Control
                            name="app_id"
                            type="text"
                            className="emailfield"
                            placeholder="Enter app id"
                            onChange={handleWpChange}
                          />
                          <p style={{ color: "red" }}>{credsError.appIdErr}</p>
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            name="app_password"
                            type="password"
                            className="emailfield"
                            placeholder="Enter password"
                            onChange={handleWpChange}
                          />
                          <p style={{ color: "red" }}>{credsError.appPassErr}</p>
                        </Form.Group>
                        <Button
                          variant="primary"
                          type="submit"
                          className="wp_submit"
                          onClick={wordpressAuth}
                        >
                          Submit
                        </Button>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div> */}
                    </div>
                    <button className="mr-2 btn btn_primary" onClick={prevStep}>
                      Back
                    </button>
                    <button className="mr-2 btn btn_primary" onClick={nextStep}>
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <div className="dash_inner_partts">
              {loader == true && (
                <div className="loader-wrapper">
                  <Audio
                    height="80"
                    width="80"
                    radius="9"
                    color="green"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                  />
                </div>
              )}
              <DashboardLeftPanel
                selectedCheckboxValue={selectedCheckboxValue}
                postInfo={postInfo}
              />
              <div className="dash_right_panel">
                <div className="dash_right_inner_panel">
                  <div className="prf_textts">
                    <h4 className="mr-5" style={{ color: "white" }}>
                      Select your social media account : {"    "}
                      <button
                        className="mr-2 btn btn_primary"
                        type="button"
                        onClick={getAllSocialAccount}
                      >
                        Refresh
                      </button>
                    </h4>
                  </div>
                  <div className="review_article_box">
                    {connectedAcc &&
                      connectedAcc.length > 0 &&
                      connectedAcc.map((acc) =>
                        acc.accounts.map((account) => {
                          console.log("platform", platform, acc);
                          return (
                            <div className="org_innr_innrr" key={account.id}>
                              {/* Check if the platform matches the account_type */}
                              {acc.account_type === "Facebook" &&
                                platform === "Facebook" && (
                                  <div className="prf_textts">
                                    <div className="org_innr_partts">
                                      <input
                                        className="styled-checkbox"
                                        id={account.id}
                                        type="checkbox"
                                        onChange={(e) => {
                                          handleAccChange(
                                            e,
                                            account.id,
                                            acc.account_type
                                          );
                                        }}
                                      />
                                      <label htmlFor={account.id}>
                                        <img
                                          src={FbLogo}
                                          alt={`${acc.account_type} Logo`}
                                        />
                                        <span>{account.name}</span>
                                      </label>
                                    </div>
                                  </div>
                                )}

                              {acc.account_type === "WordPress" &&
                                platform === "WordPress" && (
                                  <div className="prf_textts">
                                    <div className="org_innr_partts">
                                      <input
                                        className="styled-checkbox"
                                        id={account.id}
                                        type="checkbox"
                                        onChange={(e) => {
                                          handleAccChange(
                                            e,
                                            account.id,
                                            acc.account_type
                                          );
                                        }}
                                      />
                                      <label htmlFor={account.id}>
                                        <img
                                          src={Wp}
                                          alt={`${acc.account_type} Logo`}
                                        />
                                        <span>{account.name}</span>
                                      </label>
                                    </div>
                                  </div>
                                )}
                            </div>
                          );
                        })
                      )}
                  </div>
                  <div className="schedule_date_time mt-5">
                    <h3>Scheduled Date and Time</h3>
                    <div className="">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Scheduled Date</Form.Label>
                              <Form.Control
                                value={startDate}
                                type="date"
                                onChange={(e) => setStartDate(e.target.value)}
                              />
                              <div>
                                {dateError && (
                                  <p style={{ color: "red" }}>{dateError}</p>
                                )}
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>Scheduled Time</Form.Label>
                              <select
                                className="form-control"
                                value={time}
                                onChange={handleTimeChange}
                              >
                                <option value="">Select a round time</option>
                                {roundTimeOptions.map((timeOption, index) => (
                                  <option key={index} value={timeOption}>
                                    {timeOption}
                                  </option>
                                ))}
                              </select>
                              <div>
                                {timeError && (
                                  <p style={{ color: "red" }}>{timeError}</p>
                                )}
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label className="d-block">&nbsp;</label>
                          <button
                            type="button"
                            className="btn btn_primary"
                            onClick={handleSchedule}
                          >
                            Scheduled
                          </button>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn_primary mr-3"
                      onClick={prevStep}
                    >
                      Back
                    </button>
                    <button
                      className="btn btn_primary"
                      type="button"
                      onClick={handlePostPublish}
                    >
                      Publish
                    </button>
                    {/* <Button onClick={getAllSocialAccount}>Refresh</Button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return <form>{renderStep()}</form>;

  // return (
  //   <>
  //     {loader == true && (
  //       <div className="loader-wrapper">
  //         <Audio
  //           height="80"
  //           width="80"
  //           radius="9"
  //           color="green"
  //           ariaLabel="loading"
  //           wrapperStyle
  //           wrapperClass
  //         />
  //       </div>
  //     )}
  //     <div className="dash_inner_partts">
  //       <DashboardLeftPanel />
  //       <div className="dash_right_panel">
  //         <div className="dash_right_inner_panel">
  //           <div className="row">
  //             <div className="col-lg-9 col-md-12 col-sm-12">
  //               <div className="promt_templates">
  //                 <div className="row">
  //                   <div className="col-md-5">
  //                     <h2 className="title_md">Prompt Templates</h2>
  //                     <div className={`row prompt_temp_row ${prompt === true ? 'hide_propt_temp':''}`}>
  //                       <div className="col-md-4">
  //                         <div className="prompt_temp_item text-center mb-3" onClick={() => handlegetPrompt("Facebook")}>
  //                           <FontAwesomeIcon icon={faFacebookF} />
  //                           <h4 className="mb-0 mt-2">Facebook</h4>
  //                         </div>
  //                       </div>
  //                       {/* <div className="col-md-4">
  //                         <div className="prompt_temp_item text-center mb-3" onClick={() => handlegetPrompt("Instagram")}>
  //                           <FontAwesomeIcon icon={faInstagram} />
  //                           <h4 className="mb-0 mt-2">Instagram</h4>
  //                         </div>
  //                       </div>
  //                       <div className="col-md-4">
  //                         <div className="prompt_temp_item text-center mb-3" onClick={() => handlegetPrompt("LinkedIn")}>
  //                           <FontAwesomeIcon icon={faLinkedinIn} />
  //                           <h4 className="mb-0 mt-2">LinkedIn</h4>
  //                         </div>
  //                       </div>
  //                       <div className="col-md-4">
  //                         <div className="prompt_temp_item text-center mb-3" onClick={() => handlegetPrompt("Twitter")}>
  //                           <FontAwesomeIcon icon={faXTwitter} />
  //                           <h4 className="mb-0 mt-2">Twitter</h4>
  //                         </div>
  //                       </div>
  //                       <div className="col-md-4">
  //                         <div className="prompt_temp_item text-center mb-3" onClick={() => handlegetPrompt("Wordpress")}>
  //                           <FontAwesomeIcon icon={faWordpressSimple} />
  //                           <h4 className="mb-0 mt-2">Wordpress</h4>
  //                         </div>
  //                       </div> */}
  //                     </div>
  //                     {prompt === true ?
  //                       <div className="prompt_result">
  //                         <div className="prompt_result_head">
  //                           <div className="pr_back" onClick={() => setPrompt(false)}><FontAwesomeIcon icon={faArrowLeft} /></div>
  //                           {platform}
  //                         </div>
  //                         <div className="prompt_result_body">
  //                           {promptData?.map((item) => (
  //                               <p key={item.description} onClick={() => setYourPrompt(item.description)}>{item.description}</p>
  //                             ))
  //                           }
  //                         </div>
  //                       </div>
  //                       : ""
  //                       }
  //                   </div>
  //                   <div className="col-md-7">
  //                     <div className="title_bar">
  //                       <h2 className="title_sm">Your Prompt</h2>
  //                       <div className="titlebar_tools"></div>
  //                     </div>

  //                     <div className="enter_prompt_area mb-4">
  //                       <textarea className="promt_textarea form-control" value={yourPrompt} onChange={(e) => setYourPrompt(e.target.value)}
  //                       ></textarea>
  //                       {yourPromptErr && (
  //                         <p style={{ color: "red", fontSize: "14px" }}>
  //                           {yourPromptErr}
  //                         </p>
  //                       )}
  //                       {/* <div className="epa_footer">
  //                         <span className="badge_primary">936 left</span>
  //                       </div> */}
  //                     </div>

  //                     <h4 className="title_sm">Ton of Voice</h4>
  //                     <div className="tov mb-4">
  //                       <ul className="list-inline">
  //                         <li className={`list-inline-item mb-2 ${selectTone === "Polite" ? 'active_tone' : ''}`} onClick={()=>handleToneSelect("Polite")}>Polite</li>
  //                         <li className={`list-inline-item mb-2 ${selectTone === "Witty" ? 'active_tone' : ''}`} onClick={()=>handleToneSelect("Witty")}>Witty</li>
  //                         <li  className={`list-inline-item mb-2 ${selectTone === "Enthusiastic" ? 'active_tone' : ''}`} onClick={()=>handleToneSelect("Enthusiastic")}>
  //                           Enthusiastic
  //                         </li>
  //                         <li  className={`list-inline-item mb-2 ${selectTone === "Friendly" ? 'active_tone' : ''}`} onClick={()=>handleToneSelect("Friendly")}>Friendly</li>
  //                         <li  className={`list-inline-item mb-2 ${selectTone === "Informational" ? 'active_tone' : ''}`} onClick={()=>handleToneSelect("Informational")}>
  //                           Informational
  //                         </li>
  //                         <li  className={`list-inline-item mb-2 ${selectTone === "Funny" ? 'active_tone' : ''}`} onClick={()=>handleToneSelect("Funny")}>Funny</li>
  //                         {/* <li className="list-inline-item mb-2">+More</li> */}
  //                       </ul>
  //                     </div>

  //                     <div className="title_bar">
  //                       <h4 className="title_sm">Approximate Words</h4>
  //                       <div className="titlebar_tools">
  //                         <span className="ap_count">{value}</span>
  //                       </div>
  //                     </div>
  //                     <div className="apw mb-4">
  //                       <Slider
  //                         value={value}
  //                         onChange={handleChange}
  //                         min={0}
  //                         max={100}
  //                       />
  //                     </div>

  //                     <div className="title_bar justify-content-start align-items-center mb-5">
  //                       <h4 className="mb-0 title_sm">Posts To Generate</h4>
  //                       <div className="titlebar_tools ml-3">
  //                         <select
  //                           className="form-control"
  //                           value={noOfPost}
  //                           onChange={(e) => setNoOfPost(e.target.value)}
  //                         >
  //                           <option value={1}>1</option>
  //                           <option value={2}>2</option>
  //                           <option value={3}>3</option>
  //                         </select>
  //                       </div>
  //                     </div>

  //                     <button
  //                       className="btn btn_primary"
  //                       onClick={generateArticle}
  //                     >
  //                       Generate
  //                     </button>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="pr-0 col-lg-3 col-md-12 col-sm-12">
  //               <div className="prompt_rightbar">
  //                 {postInfo.length == 0 && (
  //                   <div>
  //                     <h2 className="title_sm">Get Started</h2>
  //                     <ol className="pl-3 gs_list mb-4">
  //                       <li className="mb-2">
  //                         Pick a prompt from the left-hand side “Prompt
  //                         templates” panel, use the button to get a random
  //                         prompt, or write one from scratch.
  //                       </li>
  //                       <li className="mb-2">
  //                         Write or edit your prompt with what you want the AI to
  //                         generate and hit the Generate button.
  //                       </li>
  //                       <li className="mb-2">
  //                         Select the results you like and hit the Share on
  //                         social button.
  //                       </li>
  //                     </ol>
  //                   </div>
  //                 )}
  //                 {postInfo.length !== 0 && (
  //                   <h2 className="title_sm mb-0">Results</h2>
  //                 )}
  //                 {postInfo.length !== 0 && (
  //                   <div className="sub_heading mb-3">
  //                     {postInfo.length} Results In this feed
  //                   </div>
  //                 )}
  //                 <div className="prompt_text_result">
  //                   {postInfo?.map((item, index) => (
  //                     <label>
  //                       <div key={index} className="text_result_card mb-3">
  //                         <input type="checkbox"
  //                           checked={selectedCheckbox === index}
  //                           onChange={() => handleCheckboxChange(index)}
  //                         />
  //                         <div className="pr_checkmark">
  //                           <FontAwesomeIcon icon={faCheckCircle} />
  //                         </div>
  //                         <div dangerouslySetInnerHTML={{__html: item.content.replace(/\n/g, "<br />")}}></div>
  //                         {/* {item.content} */}
  //                       </div>
  //                     </label>
  //                   ))}

  //                   {/* <div className="text_result_card mb-3">
  //                     <ul className="list-unstyled">
  //                       <li className="mb-2">
  //                         <small>
  //                           🔓 Unlock the Secrets to Boosting Your Business
  //                           Success! 💼🚀
  //                         </small>
  //                       </li>
  //                       <li className="mb-2">
  //                         <small>
  //                           Are you ready to take your business to the next
  //                           level? Look no further, because we've got five
  //                           proven strategies that are guaranteed to supercharge
  //                           your success! 💪
  //                         </small>
  //                       </li>
  //                       <li className="mb-2">
  //                         <small>
  //                           1️) Innovate, Adapt, Evolve: Stay ahead of the game
  //                           by continually innovating your products or services,
  //                           adapting to changing market trends, and evolving
  //                           your business strategies. Embrace new ideas and
  //                           technologies to keep your competitors on their toes.
  //                         </small>
  //                       </li>
  //                       <li className="mb-2">
  //                         <small>
  //                           2️) Network, Collaborate, Connect: Forge strong
  //                           relationships within your industry. Network with
  //                           like-minded professionals, collaborate on projects,
  //                           and connect with potential clients. Remember,
  //                           partnerships are often the key to unlocking new
  //                           opportunities and growth.
  //                         </small>
  //                       </li>
  //                     </ul>
  //                   </div> */}
  //                 </div>
  //                 {postInfo.length !== 0 && (
  //                   <button className="btn btn_primary" onClick={handleShare}>
  //                     Share on social
  //                   </button>
  //                 )}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default ArticleGenerateNewDesign;
