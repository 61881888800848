import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
//import "./styles.css";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import axios from "../../axios/axios";
import message from "../../messages";
import { Audio } from "react-loader-spinner";
import "./login.css";
import { LoginLogo, YellowStar, Blogger } from "../../assets/images";

const LoginPage = () => {
  const history = useHistory()
  const [slideIndex, setSlideIndex] = useState(0);
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  // const [creds, setCreds] = useState({ email: "", password: "" });
  // const [credsError, setCredsError] = useState({
  //   emailerr: "",
  //   passworderr: "",
  // });

  // const emailValidatior = () => {
  //   const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //   if (creds.email.trim() === "") {
  //     setCredsError({ emailerr: "Email is required" });
  //     return false;
  //   } else if (reg.test(creds.email) === false) {
  //     setCredsError({ emailerr: "Email is invalid" });
  //     return false;
  //   } else {
  //     setCredsError({ emailerr: "" });
  //     return true;
  //   }
  // };

  // const passwordValidator = () => {
  //   if (creds.password.trim() === "") {
  //     setCredsError({ passworderr: "Password is required" });
  //     return false;
  //   } else if (creds.password.length < 6) {
  //     setCredsError({ passworderr: "Minimum password length is 6" });
  //     return false;
  //   } else {
  //     setCredsError({ passworderr: "" });
  //     return true;
  //   }
  // };


  function validateEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return "Email is required";
    } else if (!emailPattern.test(email)) {
      return "Invalid email format";
    }
    return "";
  }
  function validatePassword(password) {
    if (!password) {
      return "Password is required";
    } else if (password.length < 6) {
      return "Password must be at least 6 characters long";
    }
    return "";
  }
  function handleEmailChange(event) {
    const value = event.target.value;
    setEmail(value);
    const errorMessage = validateEmail(value);
    setErrors((prevErrors) => ({ ...prevErrors, email: errorMessage }));
  }
  function handlePasswordChange(event) {
    const value = event.target.value;
    setPassword(value);
    const errorMessage = validatePassword(value);
    setErrors((prevErrors) => ({ ...prevErrors, password: errorMessage }));
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => prevIndex + 1);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  // login api
  const handleLogin = async (e) => {
    e.preventDefault();
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);
    if (!emailError && !passwordError) {
      let paramsData = {
        email,
        password
      }
      setLoader(true);
      await axios
        .post("/login", paramsData)
        .then((res) => {
          console.log(res.data, "<<-- res.data")
          setLoader(false);
          if (res.data.status === true) {
            message.success(res.data.message);
            localStorage.setItem("AuthUserData", JSON.stringify(res.data.data));
            localStorage.setItem("CreditDataBalance", res.data.data.user_info.credit);
            localStorage.setItem("AuthToken", res.data.data.token);
            localStorage.setItem("org_info", JSON.stringify(res.data.data.org_info))
            localStorage.setItem("role", res.data.data.user_info.type)
            setTimeout(() => {
              window.open("/dashboard-home", "_self");
            }, 1000);
          } else {
            setLoader(false);
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    } else {
      setErrors({
        email: emailError,
        password: passwordError
      });
    }
  };

  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    speed: 400,
    swipeToSlide: true,
  };

  return (
    <div className="login_page">
      {loader == true && (
        <div className="loader-wrapper">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
      <Container>
        <Row className="align-items-center">
          <Col md={6} className="login_left">
            <div className="login_inner_left">
              {/* <div className="login_logo">
                <img src={LoginLogo} width={200} />
              </div> */}
              <div className="login_form">
                <h2 className="form-title">Login</h2>
                { }
                <Form onSubmit={handleLogin}>
                  <Form.Group className="type_email_box" controlId="formBasicEmail">
                    <Form.Label>
                      User Email<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Type your email"
                      value={email}
                      onChange={handleEmailChange}
                      className="type_email"
                    />
                    <div>
                      {errors.email && (
                        <span style={{ color: "red" }}>{errors.email}</span>
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="type_email_box"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      Password<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Type your password"
                      className="type_email"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <div>
                      {errors.password && (
                        <span style={{ color: "red" }}>{errors.password}</span>
                      )}
                    </div>
                  </Form.Group>
                  <div className="forgot_pass mb-3">
                    <Link to="/forgot-password">Forgot Password?</Link>
                  </div>
                  <Button 
                    variant="default" 
                    type="submit" 
                    className="btn_primary btn-block"
                    size="lg" 
                  >
                    Login
                  </Button>
                  <div className="register_link">
                    Don’t have an account? <Link to="/registration">Register</Link>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
          <Col md={6} className="login_right">
            <div className="login_inner_right">
              <Slider className="testimonial_slider" {...settings}>
                <div className="testimonial_item">
                  <div className="yellow_star">
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                  </div>
                  <p>
                    I really like what Smart Post AI generates. I've tried a few of
                    AIs and some of them generate lots of content, but often out of
                    topic, or with phrases which have really no meaning. Especially
                    for technical content.
                  </p>
                  <div className="blog_writer d-flex align-items-center">
                    <div className="blogger_name">
                      <img src={Blogger} />
                    </div>
                    <div className="blogger_des">
                      <h3>Sonam Mitra</h3>
                      <span>Blog Writer</span>
                    </div>
                  </div>
                </div>
                <div className="testimonial_item">
                  <div className="yellow_star">
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                  </div>
                  <p>
                    I really like what Smart Post AI generates. I've tried a few of
                    AIs and some of them generate lots of content, but often out of
                    topic, or with phrases which have really no meaning. Especially
                    for technical content.
                  </p>
                  <div className="blog_writer d-flex align-items-center">
                    <div className="blogger_name">
                      <img src={Blogger} />
                    </div>
                    <div className="blogger_des">
                      <h3>Sonam Mitra</h3>
                      <span>Blog Writer</span>
                    </div>
                  </div>
                </div>
                <div className="testimonial_item">
                  <div className="yellow_star">
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                  </div>
                  <p>
                    I really like what Smart Post AI generates. I've tried a few of
                    AIs and some of them generate lots of content, but often out of
                    topic, or with phrases which have really no meaning. Especially
                    for technical content.
                  </p>
                  <div className="blog_writer d-flex align-items-center">
                    <div className="blogger_name">
                      <img src={Blogger} />
                    </div>
                    <div className="blogger_des">
                      <h3>Sonam Mitra</h3>
                      <span>Blog Writer</span>
                    </div>
                  </div>
                </div>
                <div className="testimonial_item">
                  <div className="yellow_star">
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                  </div>
                  <p>
                    I really like what Smart Post AI generates. I've tried a few of
                    AIs and some of them generate lots of content, but often out of
                    topic, or with phrases which have really no meaning. Especially
                    for technical content.
                  </p>
                  <div className="blog_writer d-flex align-items-center">
                    <div className="blogger_name">
                      <img src={Blogger} />
                    </div>
                    <div className="blogger_des">
                      <h3>Sonam Mitra</h3>
                      <span>Blog Writer</span>
                    </div>
                  </div>
                </div>
                <div className="testimonial_item">
                  <div className="yellow_star">
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                    <img src={YellowStar} />
                  </div>
                  <p>
                    I really like what Smart Post AI generates. I've tried a few of
                    AIs and some of them generate lots of content, but often out of
                    topic, or with phrases which have really no meaning. Especially
                    for technical content.
                  </p>
                  <div className="blog_writer d-flex align-items-center">
                    <div className="blogger_name">
                      <img src={Blogger} />
                    </div>
                    <div className="blogger_des">
                      <h3>Sonam Mitra</h3>
                      <span>Blog Writer</span>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;
