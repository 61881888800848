import React, { useState } from 'react';
import { useEffect } from 'react';
import axios from '../../../axios/axios';
import message from "../../../messages";

const SocialAuthPage = () => {
    useEffect( () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get('code');
        if(code){
            facebookAuthValidate(code)
        }
        let oauth_token = params.get('oauth_token');
        let oauth_verifier = params.get('oauth_verifier');
        if(oauth_token && oauth_verifier){
            twitterAuthValidate(oauth_token, oauth_verifier)
        }
    }, [])
    var called = 0;
    var AuthToken = localStorage.getItem('AuthToken');
    const facebookAuthValidate =(code) => {
        called++;
        if(called == 1){
            axios.post('/facebook/auth/validate', {code: code+"#_=_"}, {headers: {'x-access-token': AuthToken}}).then( (res) => {
                if(res.status === 200){
                    // window.location.href = '/generate-article';
                    localStorage.setItem("social_media", "Connected")
                    message.success("Connected")
                    setTimeout(()=>{
                       window.open("https://staging.d3lv58s33sw975.amplifyapp.com/social-connect", "_self")
                    },2000)
                }
            }).catch((err)=>{
                message.error(err.message)
                setTimeout(()=>{
                   window.open("https://staging.d3lv58s33sw975.amplifyapp.com/social-connect", "_self")
                },2000)
            })
        }
    }
    const twitterAuthValidate =(oauth_token, oauth_verifier) => {
        called++;
        if(called == 1){
            let secret = localStorage.getItem('twitter_secret');
            axios.post('/twitter/auth/validate', {code: oauth_token, secret: secret, oauth_verifier: oauth_verifier}, {headers: {'x-access-token': AuthToken}}).then( (res) => {
                console.log(res)
                if(res.status === 200){
                    // window.location.href = '/generate-article';
                    localStorage.setItem("social_media", "Connected")
                    message.success("Connected")
                    setTimeout(()=>{
                       window.open("https://staging.d3lv58s33sw975.amplifyapp.com/social-connect", "_self")
                    },2000)
                }
            }).catch((err)=>{
                message.error(err.message)
                setTimeout(()=>{
                   window.open("https://staging.d3lv58s33sw975.amplifyapp.com/social-connect", "_self")
                },2000)
            })
        }
    }

    return (
        <div className='social_connect'>
            <p>Please wait..</p>
        </div>
    )
}
export default SocialAuthPage;