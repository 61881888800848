import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link , useParams} from "react-router-dom";
import axios from "../../axios/axios";
import message from "../../messages";
import { Audio } from "react-loader-spinner";
import "./ForgotPassword.css";
import { LoginLogo, YellowStar, Blogger } from "../../assets/images";

const ForGotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loader, setLoader] = useState(false);

  const validateEmailField = () => {
    setEmailError("");
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email) {
      setEmailError("Please enter your email");
      return false;
    } else if (reg.test(email) === false) {
      setEmailError("Email is invalid");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmailField()) {
      setLoader(true);
      // Submit the form or perform other actions
      await axios
        .post(`/forgot-password`, {email:email})
        .then((res) => {
          setLoader(false);
            message.success("Email Send Successfully");
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }
  };

  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    speed: 400,
    swipeToSlide: true,
  };

  return (
    <div className="login_page">
      {loader == true && (
        <div className="loader-wrapper">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <div className="login_left">
              <div className="login_inner_left">
                {/* <div className="login_logo">
                  <img src={LoginLogo} width={200} />
                </div> */}
                <div className="login_form">
                  <h2 className="form-title">Forgot Password</h2>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="type_email_box" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        placeholder="Type your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="type_email"
                      />
                      <div>
                        {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                      </div>
                    </Form.Group>
                    <Button 
                      variant="default" 
                      type="submit" 
                      className="btn_primary btn-block"
                      size="lg"
                    >
                      Send
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="login_right">
              <div className="login_inner_right">
                <Slider className="testimonial_slider" {...settings}>
                  <div className="testimonial_item">
                    <div className="yellow_star">
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                    </div>
                    <p>
                      I really like what Smart Post AI generates. I've tried a few of
                      AIs and some of them generate lots of content, but often out of
                      topic, or with phrases which have really no meaning. Especially
                      for technical content.
                    </p>
                    <div className="blog_writer d-flex align-items-center">
                      <div className="blogger_name">
                        <img src={Blogger} />
                      </div>
                      <div className="blogger_des">
                        <h3>Sonam Mitra</h3>
                        <span>Blog Writer</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial_item">
                    <div className="yellow_star">
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                    </div>
                    <p>
                      I really like what Smart Post AI. I've tried a few of
                      AIs and some of them generate lots of content, but often out of
                      topic, or with phrases which have really no meaning. Especially
                      for technical content.
                    </p>
                    <div className="blog_writer d-flex align-items-center">
                      <div className="blogger_name">
                        <img src={Blogger} />
                      </div>
                      <div className="blogger_des">
                        <h3>Sonam Mitra</h3>
                        <span>Blog Writer</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial_item">
                    <div className="yellow_star">
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                    </div>
                    <p>
                      I really like what Smart Post AI generates. I've tried a few of
                      AIs and some of them generate lots of content, but often out of
                      topic, or with phrases which have really no meaning. Especially
                      for technical content.
                    </p>
                    <div className="blog_writer d-flex align-items-center">
                      <div className="blogger_name">
                        <img src={Blogger} />
                      </div>
                      <div className="blogger_des">
                        <h3>Sonam Mitra</h3>
                        <span>Blog Writer</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial_item">
                    <div className="yellow_star">
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                    </div>
                    <p>
                      I really like what Smart Post AI generates. I've tried a few of
                      AIs and some of them generate lots of content, but often out of
                      topic, or with phrases which have really no meaning. Especially
                      for technical content.
                    </p>
                    <div className="blog_writer d-flex align-items-center">
                      <div className="blogger_name">
                        <img src={Blogger} />
                      </div>
                      <div className="blogger_des">
                        <h3>Sonam Mitra</h3>
                        <span>Blog Writer</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial_item">
                    <div className="yellow_star">
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                    </div>
                    <p>
                      I really like what Smart Post AI. I've tried a few of
                      AIs and some of them generate lots of content, but often out of
                      topic, or with phrases which have really no meaning. Especially
                      for technical content.
                    </p>
                    <div className="blog_writer d-flex align-items-center">
                      <div className="blogger_name">
                        <img src={Blogger} />
                      </div>
                      <div className="blogger_des">
                        <h3>Sonam Mitra</h3>
                        <span>Blog Writer</span>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForGotPasswordPage;
