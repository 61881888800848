import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
  Accordion,
  Card,
} from "react-bootstrap";
import axios from "../../../axios/axios";
import { Audio } from "react-loader-spinner";
import message from "../../../messages";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { LoginLogo } from "../../../assets/images";
import { Link } from "react-router-dom";
const AddMember = () => {
  const { code } = useParams();

  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [cpasswordError, setCpasswordError] = useState("");
  // const [invitationToken, setInvitationToken] = useState("");
  const [isValidPhn, setIsValidPhn] = useState(false);

  const fromValidate = () => {
    setNameError("");
    setPhoneError("");
    setPasswordError("");
    setCpasswordError("");

    if (!name) {
      setNameError("Please enter your member's name");
      return false;
    }
    if (!phone) {
      setPhoneError("Please enter your member's phone number");
      return false;
    }
    if (!password) {
      setPasswordError("Please enter your password");
      return false;
    }
    if (!cpassword) {
      setCpasswordError("Please enter your confirm password");
      return false;
    } else if (password.length < 6) {
      setCpasswordError("Minimum password length is 6");
      return false;
    } else if (password !== cpassword) {
      setCpasswordError("Confirm password doesn't match");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    console.log("err check",fromValidate(), isValidPhn)
    e.preventDefault();
    if (fromValidate() && !isValidPhn) {
      let paramsData = {
        token: code,
        name,
        phone,
        password,
      };
      setLoader(true);
      // Submit the form or perform other actions
      await axios
        .post("/member-signup", paramsData)
        .then((res) => {
          setLoader(false);
          message.success(res.data.message);

          setTimeout(() => {
            window.open("/login", "_self");
          }, 2000);
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }
  };

  // const handlePhone = (value) => {
  //   setPhone(value)
  //   const numericPhoneNumber = value ? value.replace(/\D/g, "") : "";
  //   setIsValidPhn(numericPhoneNumber.length < 12);
  // };
  // const handlePhone = (value) => {
  //   // Remove non-numeric characters
  //   const numericPhoneNumber = value.replace(/\D/g, "");
  
  //   // Check if the numeric phone number has exactly 10 digits
  //   const isValidPhn = /^\d{10}$/.test(numericPhoneNumber);
  
  //   if (isValidPhn) {
  //     // If it's valid, set the phone state
  //     setPhone(numericPhoneNumber);
  //   } else {
  //     // You can optionally display an error message or provide feedback to the user
  //     // indicating that the phone number is invalid.
  //     setPhoneError("Invalid Phone Number")
  //   }
  // };

  const handlePhone = (value, data, event, formattedValue) => {
    setPhone(formattedValue);
    const numericPhoneNumber = value ? value.replace(/\D/g, "") : "";
      numericPhoneNumber.length > 10
        ? setIsValidPhn(false)
        : setIsValidPhn(true);
  }
  

  return (
    <div className="reg_page">
      {loader == true && (
        <div className="loader-wrapper">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
      <Container>
        <Row className="align-items-center">
          <Col md={7} className="reg_left">
            <div className="reg_inner_left">
              <Row className="justify-content-center">
                <Col md={8}>
                  <h3 className="form-title">Sign Up Members</h3>
                  <Form >
                      <Form.Group className="type_email_box">
                        <Form.Label>
                          Member's Name<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={name}
                          placeholder="Type your name"
                          className="type_email"
                          onChange={(e) => setName(e.target.value)}
                        />
                        <div>
                          {nameError && <p style={{ color: "red" }}>{nameError}</p>}
                        </div>
                      </Form.Group>

                      <Form.Group className="type_email_box">
                        <Form.Label>
                          Phone Number<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <PhoneInput
                          value={phone}
                          onChange={handlePhone}
                          country={'us'}
                          inputStyle={{ width: "100%" }}
                          placeholder="Enter Phone Number"
                          className="type_email"
                          countryCodeEditable={false}
                          error={isValidPhn}
                        />
                        <div>
                          {isValidPhn && (
                            <span style={{ color: "red" }}>Invalid phone number</span>
                          )}
                          {phoneError.phone && (
                            <span style={{ color: "red" }}>{phoneError.phone}</span>
                          )}
                        </div>
                      </Form.Group>

                      <Form.Group
                        className="type_email_box"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>
                          Password<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Enter Password"
                          value={password}
                          className="type_email"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div>
                          {passwordError && (
                            <p style={{ color: "red" }}>{passwordError}</p>
                          )}
                        </div>
                      </Form.Group>

                      <Form.Group
                        className="type_email_box"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>
                        Confirm Password<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter Password"
                          name="cPassword"
                          value={cpassword}
                          className="type_email"
                          onChange={(e) => setCpassword(e.target.value)}
                        />
                        <div>
                          {cpasswordError && (
                            <p style={{ color: "red" }}>{cpasswordError}</p>
                          )}
                        </div>
                      </Form.Group>
                      <Button 
                        type="submit" 
                        variant="default"
                        className="btn_primary btn-block btn-lg" 
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={5} className="reg_right">
            <div className="reg_inner_right">
              <h2>
                Smart Post AI helps your organization to build an audience
                organically
              </h2>
              <p>
                We’re a values-driven company that provides affordable, intuitive
                marketing tools for ambitious people and teams.
              </p>

              <div className="get_a_demo">
                <Link to="">Get a Demo</Link>
              </div>
            </div>
          </Col>
      </Row>
      </Container>
    </div>
  );
};
export default AddMember;
