import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
  Accordion,
  Card,
} from "react-bootstrap";
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../../components/DashboardRightPanel/dashbaordrightpanel";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Audio } from "react-loader-spinner";
import "./plan.css";

import {
  FacebookSocial,
  InsSocial,
  TwitterSocial,
  LinkedSocial,
  Toggle,
  SearchIcon,
  NameView,
  action,
  Wp,
  TwitterIconn,
} from "../../../assets/images";
import axios from "../../../axios/axios";
import { message } from "antd";
import moment from "moment";

const PlanPage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [billingHistory, setBillingHistory] = useState([]);
  const [loader, setLoader] = useState(false);
  const [plans, setPlans] = useState([]);

  const getPlans = async () => {
    const plans = await axios
      .get("/plans")
      .catch((err) => console.error(err, "<<-- Error in plans get"));
    if (plans.data.data?.length > 0) {
      setPlans(plans?.data?.data);
    }
  };

  const getBillingHistory = async () => {
    const billingHistory = await axios
      .get("/billing-history")
      .catch((error) =>
        console.error(error, "<<-- Error in billing history get")
      );
    setBillingHistory(billingHistory?.data?.data);
  };

  useEffect(() => {
    getPlans();
    getBillingHistory();
  }, []);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    setExpanded(!expanded);
  };

  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedCollapsed = localStorage.getItem("collapsed");
    if (storedCollapsed) {
      setCollapsed(JSON.parse(storedCollapsed));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("collapsed", JSON.stringify(collapsed));
  }, [collapsed]);

  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedExpanded = localStorage.getItem("expanded");
    if (storedExpanded) {
      setExpanded(JSON.parse(storedExpanded));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("expanded", JSON.stringify(collapsed));
  }, [collapsed]);

  const columns = [
    {
      name: "Sl No.",
      selector: (row) => row.no,
      width: "120px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: "Plan",
      selector: (row) => row.plan,
      width: "250px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: "Billing Date",
      selector: (row) => row.date,
      width: "250px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: "Amount",
      selector: (row) => row.amnt,
      width: "140px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
  ];

  const data1 = [
    {
      id: 1,
      no: "01",
      plan: (
        <div className="name_text d-flex align-items-center">
          <div className="name_view_sec s_connect">Basic Plan</div>
        </div>
      ),
      date: <div className="mail">20 June, 2023</div>,
      amnt: <div className="mail">$9</div>,
    },
    {
      id: 2,
      no: "02",
      plan: (
        <div className="name_text d-flex align-items-center">
          <div className="name_view_sec s_connect">Premium Plan</div>
        </div>
      ),
      date: <div className="mail">20 May, 2023</div>,
      amnt: <div className="mail">$50</div>,
    },

    {
      id: 2,
      no: "03",
      plan: (
        <div className="name_text d-flex align-items-center">
          <div className="name_view_sec s_connect">Basic Plan</div>
        </div>
      ),
      date: <div className="mail">14 May, 2023</div>,
      amnt: <div className="mail">$9</div>,
    },
    {
      id: 2,
      no: "04",
      plan: (
        <div className="name_text d-flex align-items-center">
          <div className="name_view_sec s_connect">Basic Plan</div>
        </div>
      ),
      date: <div className="mail">7 May, 2023</div>,
      amnt: <div className="mail">$9</div>,
    },
    {
      id: 2,
      no: "05",
      plan: (
        <div className="name_text d-flex align-items-center">
          <div className="name_view_sec s_connect">Basic Plan</div>
        </div>
      ),
      date: <div className="mail">7 May, 2023</div>,
      amnt: <div className="mail">$9</div>,
    },
  ];

  const setHistory = () => {
    const historyData = [];
    billingHistory?.map((data, idx) => {
      historyData.push({
        id: idx,
        no: idx + 1,
        plan: (
          <div className="name_text d-flex align-items-center">
            <div className="name_view_sec s_connect">{data?.plan_name}</div>
          </div>
        ),
        date: (
          <div className="mail">
            {moment(data?.createdAt).format("MM/DD/YYYY")}
          </div>
        ),
        amnt: <div className="mail">${data?.total_amount.toFixed(2)}</div>,
      });
    });
    return historyData;
  };

  const handleUpgrade = async (plan) => {
    setLoader(true);
    await axios
      .post("/plan-upgrade", { plan_id: plan._id })
      .then((res) => {
        setLoader(false);
        message.success(res.data.message);
        let creditPoint = localStorage.getItem("CreditDataBalance")
        creditPoint = parseFloat(creditPoint) || 0;
        creditPoint += plan.credit;
        localStorage.setItem("CreditDataBalance", creditPoint)
        setLoader(false);
        // setTimeout(() => {
          window.open(res.data.data.url, "_self");
        // }, 1000);
      })
      .catch((err) => {
        setLoader(false);
        console.error(err, "<<-- Error in plan upgrade");
      });
  };
  return (
    <section className="dash_partts">
        {loader == true && (
        <div className="loader-wrapper">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
      <div className="dash_inner_partts">
        <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
          <DashboardLeftPanel />
        </div>

        <div className={`dash_right_panel ${expanded ? "expanded" : ""}`}>
          <button onClick={toggleSidebar} className="toggle__button">
            <img src={Toggle} />
          </button>
          <div className="dash_right_inner_panel">
            <Row>
              <Col lg="9" md="12" sm="12">
                <div className="archive_article_boxx mt-4">
                  <div className="plan_heading">
                    <h3>Plans</h3>
                    <p>
                      Explore our pricing plans suitable for every budget. Get
                      exclusive benefits with your perfect subscription plan –
                      subscribe now!
                    </p>
                  </div>
                  <div className="plan_inner">
                    {plans.map((plan) => {
                      return (
                        <div className="plan_inner_partts">
                          <div className="plan_inner_inner_partts">
                            <div className="plan_inner_topper">
                              <div className="text-center">
                                <div className="plan_style">{plan.name}</div>
                              </div>
                              <div>
                              </div>
                              <div className="plan_price">
                                <span>${plan.price}</span>
                                <br/><p>{plan.type}</p>
                                {plan.plan_type}
                              </div>
                            </div>

                            <div className="generate_text_online">
                              <ul>
                                {/* {plan.features.map(feature => {
                                                                    return (
                                                                        <li>{feature.description}</li>
                                                                    )
                                                                })} */}
                                {/* <li>Generate Text One Time </li>
                                                                <li>No User Add</li>
                                                                <li>No Social Sharing</li> */}
                                <li>{plan.credit} Credits</li>
                              </ul>
                            </div>
                            <div className="button_plan">
                              <button
                                type="button"
                                disabled={plan.is_current}
                                className={
                                  plan.is_current
                                    ? "current_button"
                                    : "upgrade_button"
                                }
                                onClick={() => handleUpgrade(plan)}
                              >
                                {plan.is_current
                                  ? "Current plan"
                                  : "Upgrade plan"}
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/* <div className='plan_inner_partts'>
                                            <div className='plan_inner_inner_partts'>
                                                <div className='plan_inner_topper'>
                                                    <div className='text-center'><div className='plan_style'>Free</div></div>
                                                    <div className='plan_price'>
                                                        <span>$0 /</span>Week
                                                    </div>
                                                </div>
                                                <div className='plan_middle_text'>
                                                    <p>Most ideal plan for organizations who want to try and test out the capabilities of our AI content tool.</p>
                                                </div>

                                                <div className='generate_text_online'>
                                                    <ul>
                                                        <li>Generate Text One Time </li>
                                                        <li>No User Add</li>
                                                        <li>No Social Sharing</li>
                                                    </ul>
                                                </div>
                                                <div className='button_plan'>
                                                    <button type='button' disabled className='current_button'>Current plan</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='plan_inner_partts '>
                                            <div className='plan_inner_inner_partts active'>
                                                <div className='plan_inner_topper'>
                                                    <div className='text-center'><div className='plan_style'>Basic</div></div>
                                                    <div className='plan_price'>
                                                        <span>$9 /</span>Week
                                                    </div>
                                                </div>
                                                <div className='plan_middle_text'>
                                                    <p>Preferred pricing plan for small and medium organizations looking for automated long-form content.</p>
                                                </div>

                                                <div className='generate_text_online'>
                                                    <ul>
                                                        <li>Generate Text One Time </li>
                                                        <li>No User Add</li>
                                                        <li>No Social Sharing</li>
                                                    </ul>
                                                </div>
                                                <div className='button_plan'>
                                                    <button type='button' className='current_button'>Upgrade Plan</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='plan_inner_partts'>
                                            <div className='plan_inner_inner_partts'>
                                                <div className='plan_inner_topper'>
                                                    <div className='text-center'><div className='plan_style'>Premium</div></div>
                                                    <div className='plan_price'>
                                                        <span>$50 /</span>Month
                                                    </div>
                                                </div>
                                                <div className='plan_middle_text'>
                                                    <p>Most ideal plan for organizations who want to try and test out the capabilities of our AI content tool.</p>
                                                </div>

                                                <div className='generate_text_online'>
                                                    <ul>
                                                        <li>Get Personal Dashboard </li>
                                                        <li>Share Content on Social Media</li>
                                                        <li>Share Content On Website</li>
                                                    </ul>
                                                </div>
                                                <div className='button_plan'>
                                                    <button type='button' className='current_button'>Upgrade Plan</button>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>

                  <div className="billing_history">
                    <h3>Billing History</h3>
                    <div className="billing_data">
                      <DataTable
                        columns={columns}
                        data={setHistory()}
                        pagination
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="12" sm="12" className="pr-0">
                <div className="dash_right_paertts">
                  <DashRightPanel />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlanPage;
