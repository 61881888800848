import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
  Accordion,
  Card,
} from "react-bootstrap";
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../../components/DashboardRightPanel/dashbaordrightpanel";
import { Link } from "react-router-dom";
import "./profile.css";
import axios from "../../../axios/axios";
import message from "../../../messages";
import { Audio } from "react-loader-spinner";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";

import {
  Toggle,
  ProTopImg,
  PrfBannerEidt,
  PrfImggg,
  Social1,
  Social2,
  Social3,
  Social4,
  no_prf,
} from "../../../assets/images";

const ProfilePage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [loader, setLoader] = useState(false);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState("");

  const [orgname, setOrgName] = useState("");
  const [orgNameError, setOrgNameError] = useState("");
  const [orgphone, setOrgphone] = useState("");
  const [orgPhoneError, setOrgPhoneError] = useState("");
  const [orgCity, setorgCity] = useState("");
  const [orgCityError, setorgCityError] = useState("");
  const [orgCountry, setorgCountry] = useState("");
  const [orgCountryError, setorgCountryError] = useState("");
  const [orgzipcode, setorgzipcode] = useState("");
  const [orgzipcodeError, setorgzipcodeError] = useState("");
  const [orgState, setorgState] = useState("");
  const [orgStateError, setorgStateError] = useState("");
  const [orgAddress, setorgAddress] = useState("");
  const [orgAddressError, setorgAddressError] = useState("");

  const [orgurl, setOrgurl] = useState("");
  // const [orgUrlError, setOrgUrlError] = useState("");
  const [orgemail, setOrgemail] = useState("");
  const [description, setDescription] = useState("");
  const [isValidOrgPhn, setIsValidOrgPhn] = useState(false);
  const [isValidPhn, setIsValidPhn] = useState(false);

  const [selectedProfile, setSelectedProfile] = useState(null);
  const [apiImg, setApiImg] = useState("");
  const [selectedCover, setSelectedCover] = useState(null);
  const [apiCover, setApiCover] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const [options, setOptions] = useState([]);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    setExpanded(!expanded);
  };

  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedCollapsed = localStorage.getItem("collapsed");
    if (storedCollapsed) {
      setCollapsed(JSON.parse(storedCollapsed));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("collapsed", JSON.stringify(collapsed));
  }, [collapsed]);

  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedExpanded = localStorage.getItem("expanded");
    if (storedExpanded) {
      setExpanded(JSON.parse(storedExpanded));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("expanded", JSON.stringify(collapsed));
  }, [collapsed]);

  const userValidate = () => {
    setNameError("");
    setPhoneError("");
    if (!name) {
      setNameError("Please enter your name");
      return false;
    }
    if (!phone) {
      setPhoneError("Please enter your phone number");
      return false;
    }

    return true;
  };
  const handleUserUpdate = async (e) => {
    e.preventDefault();
    if (userValidate() && !isValidPhn) {
      let paramsData = {
        name,
        phone,
      };
      setLoader(true);
      // Submit the form or perform other actions
      await axios
        .post("/profile-update", paramsData)
        .then((res) => {
          setLoader(false);
          message.success(res.data.message);
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }
  };

  const ProfileUpdate = async () => {
    const formData = new FormData();
    formData.append("picture", selectedProfile);
    formData.append("type", "Profile");

    setLoader(true);
    // Submit the form or perform other actions
    await axios
      .post("/picture-update", formData)
      .then((res) => {
        setLoader(false);
        // message.success(res.data.message);

        setTimeout(() => {
          window.open("profile", "_self");
        }, 200);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        // message.error(err.response.data.message);
      });
  };
  const CoverUpdate = async () => {
    const formData = new FormData();
    formData.append("picture", selectedCover);
    formData.append("type", "Cover");

    setLoader(true);
    // Submit the form or perform other actions
    await axios
      .post("/picture-update", formData)
      .then((res) => {
        setLoader(false);
        // message.success(res.data.message);

        setTimeout(() => {
          window.open("profile", "_self");
        }, 200);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        // message.error(err.response.data.message);
      });
  };

  const ImageUpdate = async (e) => {
    // const formData = new FormData();

    // if(selectedProfile){
    //   formData.append("picture", selectedProfile)
    //   formData.append("type", "Profile")
    // }
    // if(selectedCover){
    //   formData.append("picture", selectedCover)
    //   formData.append("type", "Cover")
    // }

    // setLoader(true);
    // // Submit the form or perform other actions
    // await axios
    //   .post("/picture-update", formData)
    //   .then((res) => {
    //     setLoader(false);
    //     // message.success(res.data.message);

    //     setTimeout(() => {
    //       window.open("profile", "_self");
    //     }, 200);
    //   })
    //   .catch((err) => {
    //     setLoader(false);
    //     console.log("err", err);
    //     // message.error(err.response.data.message);
    //   });

    if (selectedProfile) {
      ProfileUpdate();
    }
    if (selectedCover) {
      CoverUpdate();
    }
  };

  const orgValidate = () => {
    setOrgNameError("");
    setOrgPhoneError("");
    setorgCityError("");
    setorgCountryError("");
    setorgStateError("");
    setorgzipcodeError("");
    // setOrgUrlError("");
    if (!orgname) {
      setNameError("Please enter your name");
      return false;
    }
    if (!orgAddress) {
      setorgAddressError("Please enter your address");
      return false;
    }
    if (!orgCity) {
      setorgCityError("Please enter your city");
      return false;
    }
    if (!orgCountry) {
      setorgCountryError("Please enter your country");
      return false;
    }
    if (!orgState) {
      setorgStateError("Please enter your state");
      return false;
    }
    if (!orgzipcode) {
      setorgzipcodeError("Please enter your zip code");
      return false;
    }
    if (!orgphone) {
      setOrgPhoneError("Please enter your phone number");
      return false;
    }
    // if (!orgurl) {
    //   setPhoneError("Please enter your phone number");
    //   return false;
    // }

    return true;
  };

  const getCountryList = async () => {
    await axios
      .get(`/country-list`)
      .then((res) => {
        setLoader(false);
        let arr = [];
        for (let i = 0; i < res.data.data.length; i++) {
          let newObj = { value: res.data.data[i], label: res.data.data[i] };
          arr.push(newObj);
        }
        setOptions(arr);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCountryList();
  }, []);

  const handleOrgUpdate = async (e) => {
    e.preventDefault();
    if (orgValidate() && !isValidOrgPhn) {
      ImageUpdate();
      let paramsData = {
        name: orgname,
        phone: orgphone,
        description,
        orgaddress: orgAddress,
        orgcity: orgCity,
        orgstate: orgState,
        orgcountry: orgCountry,
        orgzipcode: orgzipcode,
      };
      setLoader(true);
      // Submit the form or perform other actions
      await axios
        .post("/org-update", paramsData)
        .then((res) => {
          setLoader(false);
          message.success(res.data.message);
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }
  };

  const getProfileData = async () => {
    await axios
      .get(`/my-profile`)
      .then((res) => {
        setLoader(false);
        setName(res.data.data.user_info.name);
        setEmail(res.data.data.user_info.email);
        setPhone(res.data.data.user_info.phone);

        setApiCover(res.data.data.org_info.cover_picture);
        setApiImg(res.data.data.org_info.profile_picture);
        setOrgName(res.data.data.org_info.name);
        setOrgemail(res.data.data.org_info.email);
        setOrgphone(res.data.data.org_info.phone);
        setorgCity(res.data.data.org_info.city);
        setorgCountry(res.data.data.org_info.country);
        setorgzipcode(res.data.data.org_info.zipcode);
        setorgState(res.data.data.org_info.state);
        setOrgurl(res.data.data.org_info.url);
        setDescription(res.data.data.org_info.description);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const handleOrgPhone = (value, data, event, formattedValue) => {
    setOrgphone(formattedValue);
    const numericOrgPhoneNumber = value ? value.replace(/\D/g, "") : "";
    numericOrgPhoneNumber.length > 10
      ? setIsValidOrgPhn(false)
      : setIsValidOrgPhn(true);
  };

  const handlePhone = (value, data, event, formattedValue) => {
    setPhone(formattedValue);
    const numericPhoneNumber = value ? value.replace(/\D/g, "") : "";
    numericPhoneNumber.length > 10 ? setIsValidPhn(false) : setIsValidPhn(true);
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <section className="dash_partts">
      {loader == true && (
        <div className="loader-wrapper">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
      <div className="dash_inner_partts">
        <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
          <DashboardLeftPanel />
        </div>

        <div className={`dash_right_panel ${expanded ? "expanded" : ""}`}>
          <button onClick={toggleSidebar} className="toggle__button">
            <img src={Toggle} />
          </button>
          <div className="dash_right_inner_panel">
            <Row>
              <Col lg="9" md="12" sm="12">
                <div className="dash_innnner_ppannell">
                  <div className="prf_panel_topper">
                    <div className="prf_banner" id="Cover">
                      {apiCover !== "" && selectedCover == null ? (
                        <img src={apiCover} className="img-fluid" />
                      ) : selectedCover !== null && apiCover == "" ? (
                        <img src={ProTopImg} className="img-fluid" />
                      ) : (
                        <img
                          src={
                            apiCover !== "" &&
                            URL.createObjectURL(selectedCover)
                          }
                          className="img-fluid"
                          alt="Cover"
                        />
                      )}
                      <div className="prf_banner_upload">
                        <div className="upload-btn-wrapper">
                          <button className="btn">
                            <img src={PrfBannerEidt} />
                          </button>
                          <input
                            type="file"
                            name="myfile"
                            accept="image/*"
                            onChange={(event) => {
                              console.log("cover", selectedCover);
                              setSelectedCover(event.target.files[0]);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="prf_imggg" id="Profile">
                      {apiImg !== "" && selectedProfile == null ? (
                        <img src={apiImg} />
                      ) : selectedProfile !== null && apiImg == "" ? (
                        <img src={no_prf} />
                      ) : (
                        <img
                          src={
                            apiImg !== "" &&
                            URL.createObjectURL(selectedProfile)
                          }
                          alt="Profile"
                        />
                      )}

                      <div className="prf_banner_upload prf_img_upload">
                        <div className="upload-btn-wrapper">
                          <button className="btn">
                            <img src={PrfBannerEidt} />
                          </button>
                          <input
                            type="file"
                            name="myfile"
                            accept="image/*"
                            onChange={(event) => {
                              setSelectedProfile(event.target.files[0]);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prf_panel_bottom">
                    <div className="prf_text_imgg">
                      <Row>
                        <Col lg={8} md={8}>
                          <div className="prf_textts">
                            <h4>{orgname}</h4>
                            <p>{description}</p>
                          </div>
                        </Col>
                        <Col lg={4} md={4}>
                          <div className="social_iconns">
                            <div className="d-flex justify-content-end">
                              <Link to="#" className="mx-2">
                                <img src={Social2} width={30} />
                              </Link>
                              <Link to="#" className="mx-2">
                                <img src={Social4} width={30} />
                              </Link>
                              <Link to="#" className="mx-2">
                                <img src={Social1} width={30} />
                              </Link>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  {localStorage.getItem("role") !== "Individual" && (
                    <div className="org_prf">
                      <div className="org_heading">
                        <h3>Organization Profile</h3>
                      </div>
                      <div className="org_field">

                        <Row>
                          <Col lg="6" md="6">
                            <Form.Group
                              className="mb-4 org_fielder"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Organization Name</Form.Label>
                              <Form.Control
                                type="text"
                                value={orgname}
                                readOnly={
                                  localStorage.getItem("role") !== "Member"
                                    ? false
                                    : true
                                }
                                placeholder="Enter Your Name"
                                className="org_text_field"
                                onChange={(e) => setOrgName(e.target.value)}
                              />
                              <div>
                                {orgNameError && (
                                  <p style={{ color: "red" }}>{orgNameError}</p>
                                )}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col lg="6" md="6">
                            <Form.Group
                              className="mb-4 org_fielder"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Organization Email</Form.Label>
                              <Form.Control
                                type="email"
                                value={orgemail}
                                readOnly
                                placeholder="name@example.com"
                                className="org_text_field"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg="6" md="6">
                          <Form.Group
                            className="mb-4 org_fielder"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>About The Organization</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              readOnly={
                                localStorage.getItem("role") !== "Member"
                                  ? false
                                  : true
                              }
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </Form.Group>
                          </Col>

                          <Col lg="6" md="6">
                            <Form.Group
                              className="mb-4 org_fielder"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Organization Address</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                readOnly={
                                  localStorage.getItem("role") !== "Member"
                                    ? false
                                    : true
                                }
                                value={orgAddress}
                                onChange={(e) => setorgAddress(e.target.value)}
                              />
                              <div>
                                {orgAddressError && (
                                  <p style={{ color: "red" }}>{orgAddressError}</p>
                                )}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col lg="6" md="6">
                            <Form.Group
                              className="mb-4 org_fielder"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Organization City</Form.Label>
                              <Form.Control
                                value={orgCity}
                                type="text"
                                className="org_text_field"
                                readOnly={
                                  localStorage.getItem("role") !== "Member"
                                    ? false
                                    : true
                                }
                                onChange={(e) => setorgCity(e.target.value)}
                              />
                              <div>
                                {orgCityError && (
                                  <p style={{ color: "red" }}>{orgCityError}</p>
                                )}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col lg="6" md="6">
                            <Form.Group
                              className="mb-4 org_fielder"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Organization Country</Form.Label>
                              <Select
                                // className="org_text_field"
                                value={{value: orgCountry, label: orgCountry}}
                                readOnly={
                                  localStorage.getItem("role") !== "Member"
                                    ? false
                                    : true
                                }
                                onChange={(selectedOption) =>
                                  // console.log(selectedOption)
                                  setorgCountry(selectedOption.value)
                                }
                                options={options}
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    height: "52px",
                                    "min-height": "52px",
                                    "border-radius": "10px",
                                    "border-width": "2px",
                                    borderColor: state.isFocused ? "#80bdff" : "#d9d9d9",
                                    backgroundColor: "rgba(251, 251, 251, 0.06)",
                                  }),
                                  singleValue: (base) => ({
                                    ...base,
                                    color: "white",
                                  }),
                                  option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isFocused ? "rgba(251, 251, 251, 0.06)" : "#333",
                                    color: "white", 
                                  }),
                                }}
                              />
                              <div>
                                {orgCountryError && (
                                  <span style={{ color: "red" }}>
                                    {orgCountryError}
                                  </span>
                                )}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col lg="6" md="6">
                            <Form.Group
                              className="mb-4 org_fielder"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Organization State</Form.Label>
                              <Form.Control
                                value={orgState}
                                type="text"
                                className="org_text_field"
                                readOnly={
                                  localStorage.getItem("role") !== "Member"
                                    ? false
                                    : true
                                }
                                onChange={(e) => setorgState(e.target.value)}
                              />
                              <div>
                                {orgStateError && (
                                  <p style={{ color: "red" }}>
                                    {orgStateError}
                                  </p>
                                )}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col lg="6" md="6">
                            <Form.Group
                              className="mb-4 org_fielder"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Organization Zip Code</Form.Label>
                              <Form.Control
                                type="text"
                                className="org_text_field"
                                value={orgzipcode}
                                readOnly={
                                  localStorage.getItem("role") !== "Member"
                                    ? false
                                    : true
                                }
                                onChange={(e) => setorgzipcode(e.target.value)}
                              />
                              <div>
                                {orgzipcodeError && (
                                  <p style={{ color: "red" }}>
                                    {orgzipcodeError}
                                  </p>
                                )}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col lg="6" md="6">
                            <Form.Group
                              className="mb-4 org_fielder"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Organization Phone</Form.Label>
                              <PhoneInput
                                disabled={
                                  localStorage.getItem("role") === "Member"
                                }
                                value={orgphone}
                                //country={'us'}
                                onChange={handleOrgPhone}
                                inputStyle={{ width: "100%" }}
                                countryCodeEditable={false}
                                placeholder="Enter organization phone number"
                              />
                              <div>
                                {isValidOrgPhn && (
                                  <span style={{ color: "red" }}>
                                    Invalid Organization phone number
                                  </span>
                                )}
                                {orgPhoneError && (
                                  <p style={{ color: "red" }}>
                                    {orgPhoneError}
                                  </p>
                                )}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col lg="6" md="6">
                            <Form.Group
                              className="mb-4 org_fielder"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                Organization Website (Optional)
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={orgurl}
                                placeholder="www.example.com"
                                readOnly={
                                  localStorage.getItem("role") !== "Member"
                                    ? false
                                    : true
                                }
                                className="org_text_field"
                                onChange={(e) => setOrgurl(e.target.value)}
                              />
                              {/* <div>
                              {orgUrlError && (
                                <p style={{ color: "red" }}>{orgUrlError}</p>
                              )}
                            </div> */}
                            </Form.Group>
                          </Col>
                        </Row>

                        <div className="">
                          <Button
                            type="button"
                            variant="default"
                            className="btn_primary"
                            onClick={handleOrgUpdate}
                          >
                            Save Changes
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="org_prf">
                    <div className="org_heading">
                      <h3>Member Profile</h3>
                    </div>
                    <div className="org_field">
                      <Row>
                        <Col lg="6" md="6">
                          <Form.Group
                            className="mb-4 org_fielder"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Member Name</Form.Label>
                            <Form.Control
                              type="text"
                              value={name}
                              placeholder="Enter Your Name"
                              className="org_text_field"
                              onChange={(e) => setName(e.target.value)}
                            />
                            <div>
                              {nameError && (
                                <p style={{ color: "red" }}>{nameError}</p>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="6" md="6">
                          <Form.Group
                            className="mb-4 org_fielder"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Member Email</Form.Label>
                            <Form.Control
                              type="email"
                              value={email}
                              placeholder="name@example.com"
                              className="org_text_field"
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="6" md="6">
                          <Form.Group
                            className="mb-4 org_fielder"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Member Phone</Form.Label>
                            <PhoneInput
                              //country={'us'}
                              value={phone}
                              onChange={handlePhone}
                              inputStyle={{ width: "100%" }}
                              placeholder="Enter Phone Number"
                              countryCodeEditable={false}
                            />
                            <div>
                              {isValidPhn && (
                                <span style={{ color: "red" }}>
                                  Invalid phone number
                                </span>
                              )}
                              {phoneError && (
                                <p style={{ color: "red" }}>{phoneError}</p>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <div className="">
                        <Button
                          type="button"
                          variant="default"
                          className="btn_primary"
                          onClick={handleUserUpdate}
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="org_prf">
                    <div className="org_heading">
                      <h3>Social Links</h3>
                    </div>
                    <div className="org_field">
                      <Row>
                        <Col lg="6" md="6">
                          <Form.Group
                            className="mb-4 org_fielder"
                            // controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Facebook Page Link</Form.Label>
                            <Form.Control
                              type="text"
                              className="org_text_field"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="6" md="6">
                          <Form.Group
                            className="mb-4 org_fielder"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Instagram Page Link</Form.Label>
                            <Form.Control
                              type="text"
                              className="org_text_field"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="6" md="6">
                          <Form.Group
                            className="mb-4 org_fielder"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Twitter Page Link</Form.Label>
                            <Form.Control
                              type="text"
                              className="org_text_field"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="6" md="6">
                          <Form.Group
                            className="mb-4 org_fielder"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Linkedin Page Link</Form.Label>
                            <Form.Control
                              type="text"
                              className="org_text_field"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="6" md="6">
                          <Form.Group
                            className="mb-4 org_fielder"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Blog Page Link</Form.Label>
                            <Form.Control
                              type="text"
                              className="org_text_field"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="">
                        <Button
                          type="button"
                          variant="default"
                          className="btn_primary"
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="12" sm="12" className="pr-0">
                <div className="dash_right_paertts">
                  <DashRightPanel />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfilePage;
